import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import LoadingScreen from '../components/LoadingScreen';
import useLocales from "../hooks/useLocales";



const Loadable = (Component) => (props) => {
  const { pathname } = useLocation();
  const isDashboard = pathname?.includes(`dashboard`);

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { currentLang } = useLocales();
  return useRoutes([
    // {path: `/`, element:<HomePage/> },
    {path: `/`, element:<LandingPage /> },
    // {path: `/profile`, element:<PatientProfile/> },
    // {path: `/consulting`, element:<ConsaltingProfile/> },
    // {path: `/for-partner`, element: <LandingPage />},
    { path: `/about-us`, element: <AboutPage /> },
    { path: `/contact-us`, element: <ContactPage /> },
    { path: `/privacy-policy`, element: <PrivacyPolicy /> },
    { path: `/terms-and-condition`, element: <TermsAndCondition /> },
    // { path: `/clinics/:id`, element: <ClinicProfile /> },
    // { path: `/clinics`, element: <Clinices /> },
    // { path: `/doctors`, element: <Doctors /> },
    // { path: `/doctors/:id`, element: <DoctorsInformation /> },
    {
      path: '/auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'signup',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: `verification`, element: <Verification /> },
      ]
    },
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/admin', element: <Navigate to="/admin/app" replace /> },
        { path: 'app', element: <GeneralAdmin /> },
        { path: 'profile', element: <UserAccount /> },

        { path: 'food/list', element: <FoodList /> },
        { path: 'food/add', element: <FoodAdd /> },
        { path: 'food/edit/:id', element: <FoodEdit /> },

        { path: 'food-type/list', element: <FoodTypeList /> },
        { path: 'food-type/add', element: <FoodTypeAdd /> },
        { path: 'food-type/edit/:id', element: <FoodTypeEdit /> },

        { path: 'partner/list', element: <PartnerList /> },
        { path: 'partner/show/:id', element: <PartnerShow /> },

        { path: 'trainer-type/list', element: <TrainerTypeList /> },
        { path: 'trainer-type/add', element: <TrainerTypeAdd /> },
        { path: 'trainer-type/edit/:id', element: <TrainerTypeEdit /> },

        { path: 'liquid-category/list', element: <LiquidCategoryList /> },
        { path: 'liquid-category/add', element: <LiquidCategoryAdd /> },
        { path: 'liquid-category/edit/:id', element: <LiquidCategoryEdit /> },

        { path: 'weight/list', element: <WeightList /> },
        { path: 'weight/add', element: <WeightAdd /> },
        { path: 'weight/edit/:id', element: <WeightEdit /> },

        { path: 'target/list', element: <TargetList /> },
        { path: 'target/add', element: <TargetAdd /> },
        { path: 'target/edit/:id', element: <TargetEdit /> },

        { path: 'user-level/list', element: <UserLevelList /> },
        { path: 'user-level/add', element: <UserLevelAdd /> },
        { path: 'user-level/edit/:id', element: <UserLevelEdit /> },

        { path: 'activity-level/list', element: <ActivityLevelList /> },
        { path: 'activity-level/add', element: <ActivityLevelAdd /> },
        { path: 'activity-level/edit/:id', element: <ActivityLevelEdit /> },

        { path: 'age-range/list', element: <AgeRangeList /> },
        { path: 'age-range/add', element: <AgeRangeAdd /> },
        { path: 'age-range/edit/:id', element: <AgeRangeEdit /> },

        { path: 'bmi-range/list', element: <BmiRangeList /> },
        { path: 'bmi-range/add', element: <BmiRangeAdd /> },
        { path: 'bmi-range/edit/:id', element: <BmiRangeEdit /> },

        { path: 'exercise-category/list', element: <ExerciseCategoryList /> },
        { path: 'exercise-category/add', element: <ExerciseCategoryAdd /> },
        { path: 'exercise-category/edit/:id', element: <ExerciseCategoryEdit /> },

        { path: 'liquid/list', element: <LiquidList /> },
        { path: 'liquid/add', element: <LiquidAdd /> },
        { path: 'liquid/edit/:id', element: <LiquidEdit /> },

        { path: 'muscle-category/list', element: <MuscleCategoryList /> },
        { path: 'muscle-category/add', element: <MuscleCategoryAdd /> },
        { path: 'muscle-category/edit/:id', element: <MuscleCategoryEdit /> },

        { path: 'height/list', element: <HeightList /> },
        { path: 'height/add', element: <HeightAdd /> },
        { path: 'height/edit/:id', element: <HeightEdit /> }
      ]
    },

    // Dashboard Routes
    {
      path: `/dashboard`,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: ``, element: <Navigate to="overview" replace /> },
        { path: `overview`, element: <GeneralApp /> },
        { path: `profile`, element: <UserAccount /> },
        { path: `subscription`, element: <Subscription /> },
        { path: `success`, element: <SubscriptionSuccess /> },
        { path: `staff/list`, element: <StaffList /> },
        { path: `staff/show/:id`, element: <StaffShow /> },
        { path: `staff/add`, element: <StaffAdd /> },
        { path: `staff/edit/:id`, element: <StaffEdit /> },


        { path: `doctors/list`, element: <DoctorsList /> },
        { path: `doctors/show/:id`, element: <DoctorsShow /> },
        { path: `doctors/add`, element: <DoctorsAdd /> },
        { path: `doctors/edit/:id`, element: <DoctorsEdit /> },

        { path: `service`, element: <Service /> },
        { path: `service/lab`, element: <ServiceLab /> },
        { path: `service/list`, element: <ServiceList /> },
        { path: `service/add`, element: <ServiceAdd /> },
        { path: `service/edit/:id`, element: <ServiceEdit /> },


        { path: `skill/list`, element: <SkillList /> },
        { path: `skill/add`, element: <SkillAdd /> },
        { path: `skill/edit/:id`, element: <SkillEdit /> },


        { path: `Schedule`, element: <ScheduleLab /> },
        { path: `Schedule/doctor`, element: <ScheduleDoctor /> },
        { path: `subscribe`, element: <Subscribe /> },

        { path: `other-expenses/list`, element: <OtherExpensesList /> },
        { path: `other-expenses/add`, element: <OtherExpensesAdd /> },


        { path: `finance`, element: <FinanceVerify /> },
        { path: `finance/overview`, element: <FinanceOverview /> },
        { path: `finance/staff`, element: <FinanceStaff /> },
        { path: `finance/doctors`, element: <FinanceDoctors /> },
        { path: `finance/staff/:id`, element: <FinanceStaffOverview /> },
        { path: `finance/staff/work/:id`, element: <FinanceStaffWork /> },
        { path: `finance/doctors/:id`, element: <FinanceDoctorOverview /> },
        { path: `finance/other-expenses`, element: <OtherExpensesAdd /> },
        { path: `finance/invoice`, element: <InvoiceList /> },
        { path: `finance/invoice/:id`, element: <InvoiceAdd /> },
        { path: `finance/invoice/add`, element: <InvoiceAdd /> },
        { path: `finance/doctor/work/:id`, element: <FinanceDoctorWork /> },
        { path: `finance/labs`, element: <FinanceLabs /> },
        { path: `finance/labs/:id`, element: <FinanceLabOverview /> },
        { path: `finance/labs/work/:id`, element: <FinanceLabWork /> },


        { path: `labs`, element: <LabList /> },


        { path: `patient/list`, element: <PatientList /> },
        { path: `patient/add`, element: <PatientAdd /> },
        { path: `patient/edit/:id`, element: <PatientEdit /> },

      ]
    },
    { path: `404`, element: <General404 /> },
    { path: '*', element: <Navigate to={`404`} replace /> }
  ]);
}

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
const Verification = Loadable(lazy(() => import('../pages/authentication/Verification')));
// Dashboard
const StaffList = Loadable(lazy(() => import('../pages/staff/StaffList')));
const StaffShow = Loadable(lazy(() => import('../pages/staff/StaffShow')));
const StaffAdd = Loadable(lazy(() => import('../pages/staff/StaffAdd')));
const StaffEdit = Loadable(lazy(() => import('../pages/staff/StaffEdit')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));

const DoctorsList = Loadable(lazy(() => import('../pages/doctors/DoctorsList')));
const DoctorsShow = Loadable(lazy(() => import('../pages/doctors/DoctorsShow')));
const DoctorsAdd = Loadable(lazy(() => import('../pages/doctors/DoctorsAdd')));
const DoctorsEdit = Loadable(lazy(() => import('../pages/doctors/DoctorsEdit')));
const Schedule = Loadable(lazy(() => import('../pages/schedule/Schedule')));
const ScheduleLab = Loadable(lazy(() => import('../pages/schedule/ScheduleLab')));
const ScheduleDoctor = Loadable(lazy(() => import('../pages/schedule/ScheduleDoctor')));
const Subscribe = Loadable(lazy(() => import('../pages/subscribe/Subscribe')));

const OtherExpensesList = Loadable(lazy(() => import('../pages/other-expenses/OtherExpensesList')));
const OtherExpensesAdd = Loadable(lazy(() => import('../pages/other-expenses/OtherExpensesAdd')));

const ServiceList = Loadable(lazy(() => import('../pages/service/ServiceList')));
const Service = Loadable(lazy(() => import('../pages/service/Service')));
const ServiceLab = Loadable(lazy(() => import('../pages/service/ServiceLab')));
const ServiceAdd = Loadable(lazy(() => import('../pages/service/ServiceAdd')));
const ServiceEdit = Loadable(lazy(() => import('../pages/service/ServiceEdit')));

const SkillList = Loadable(lazy(() => import('../pages/skill/SkillList')));
const SkillAdd = Loadable(lazy(() => import('../pages/skill/SkillAdd')));
const SkillEdit = Loadable(lazy(() => import('../pages/skill/SkillEdit')));

const FinanceVerify = Loadable(lazy(() => import('../pages/finance/FinanceVerify')));
const FinanceOverview = Loadable(lazy(() => import('../pages/finance/FinanceOverview')));
const FinanceStaff = Loadable(lazy(() => import('../pages/finance/FinanceStaff')));
const FinanceDoctors = Loadable(lazy(() => import('../pages/finance/FinanceDoctors')));
const FinanceStaffOverview = Loadable(lazy(() => import('../pages/finance/FinanceStaffOverview')));
const FinanceDoctorOverview = Loadable(lazy(() => import('../pages/finance/FinanceDoctorOverview')));
// const FinanceStaffWork = Loadable(lazy(() => import('../pages/finance/FinanceStaffWork')));

const GroupList = Loadable(lazy(() => import('../pages/group/GroupList')));
const GroupShow = Loadable(lazy(() => import('../pages/group/GroupShow')));
const GroupAdd = Loadable(lazy(() => import('../pages/group/GroupAdd')));
const GroupEdit = Loadable(lazy(() => import('../pages/group/GroupEdit')));



const ExercisesList = Loadable(lazy(() => import('../pages/exercises/ExercisesList')));
const ExercisesShow = Loadable(lazy(() => import('../pages/exercises/ExercisesShow')));
const ExercisesAdd = Loadable(lazy(() => import('../pages/exercises/ExercisesAdd')));
const ExercisesEdit = Loadable(lazy(() => import('../pages/exercises/ExercisesEdit')));

const WorkOutsList = Loadable(lazy(() => import('../pages/workouts/WorkOutsList')));
const WorkOutsShow = Loadable(lazy(() => import('../pages/workouts/WorkOutsShow')));
const WorkOutsAdd = Loadable(lazy(() => import('../pages/workouts/WorkOutsAdd')));
const WorkOutsEdit = Loadable(lazy(() => import('../pages/workouts/WorkOutsEdit')));

const MealsList = Loadable(lazy(() => import('../pages/meals/MealsList')));
const MealsShow = Loadable(lazy(() => import('../pages/meals/MealsShow')));
const MealsAdd = Loadable(lazy(() => import('../pages/meals/MealsAdd')));
const MealsEdit = Loadable(lazy(() => import('../pages/meals/MealsEdit')));

const ProgramsList = Loadable(lazy(() => import('../pages/programs/ProgramsList')));
const ProgramsShow = Loadable(lazy(() => import('../pages/programs/ProgramsShow')));
const ProgramsAdd = Loadable(lazy(() => import('../pages/programs/ProgramsAdd')));
const ProgramsEdit = Loadable(lazy(() => import('../pages/programs/ProgramsEdit')));

//ADMIN
const FoodAdd = Loadable(lazy(() => import('../pages/admin/food/FoodAdd')));
const FoodList = Loadable(lazy(() => import('../pages/admin/food/FoodList')));
const FoodEdit = Loadable(lazy(() => import('../pages/admin/food/FoodEdit')));

const FoodTypeList = Loadable(lazy(() => import('../pages/admin/foodtype/FoodTypeList')));
const FoodTypeEdit = Loadable(lazy(() => import('../pages/admin/foodtype/FoodTypeEdit')));
const FoodTypeAdd = Loadable(lazy(() => import('../pages/admin/foodtype/FoodTypeAdd')));

const PartnerShow = Loadable(lazy(() => import('../pages/admin/partner/PartnerShow')));
const PartnerList = Loadable(lazy(() => import('../pages/admin/partner/PartnerList')));

const TrainerTypeList = Loadable(lazy(() => import('../pages/admin/trainertype/TrainerTypeList')));
const TrainerTypeEdit = Loadable(lazy(() => import('../pages/admin/trainertype/TrainerTypeEdit')));
const TrainerTypeAdd = Loadable(lazy(() => import('../pages/admin/trainertype/TrainerTypeAdd')));

const LiquidCategoryList = Loadable(lazy(() => import('../pages/admin/liquidCategory/LiquidCategoryList')));
const LiquidCategoryEdit = Loadable(lazy(() => import('../pages/admin/liquidCategory/LiquidCategoryEdit')));
const LiquidCategoryAdd = Loadable(lazy(() => import('../pages/admin/liquidCategory/LiquidCategoryAdd')));

const WeightList = Loadable(lazy(() => import('../pages/admin/weight/WeightList')));
const WeightEdit = Loadable(lazy(() => import('../pages/admin/weight/WeightEdit')));
const WeightAdd = Loadable(lazy(() => import('../pages/admin/weight/WeightAdd')));

const TargetList = Loadable(lazy(() => import('../pages/admin/target/TargetList')));
const TargetEdit = Loadable(lazy(() => import('../pages/admin/target/TargetEdit')));
const TargetAdd = Loadable(lazy(() => import('../pages/admin/target/TargetAdd')));

const UserLevelList = Loadable(lazy(() => import('../pages/admin/userLevel/UserLevelList')));
const UserLevelEdit = Loadable(lazy(() => import('../pages/admin/userLevel/UserLevelEdit')));
const UserLevelAdd = Loadable(lazy(() => import('../pages/admin/userLevel/UserLevelAdd')));

const ActivityLevelList = Loadable(lazy(() => import('../pages/admin/activityLevel/ActivityLevelList')));
const ActivityLevelEdit = Loadable(lazy(() => import('../pages/admin/activityLevel/ActivityLevelEdit')));
const ActivityLevelAdd = Loadable(lazy(() => import('../pages/admin/activityLevel/ActivityLevelAdd')));

const AgeRangeList = Loadable(lazy(() => import('../pages/admin/age/AgeRangeList')));
const AgeRangeEdit = Loadable(lazy(() => import('../pages/admin/age/AgeRangeEdit')));
const AgeRangeAdd = Loadable(lazy(() => import('../pages/admin/age/AgeRangeAdd')));

const BmiRangeList = Loadable(lazy(() => import('../pages/admin/bmi/BmiRangeList')));
const BmiRangeEdit = Loadable(lazy(() => import('../pages/admin/bmi/BmiRangeEdit')));
const BmiRangeAdd = Loadable(lazy(() => import('../pages/admin/bmi/BmiRangeAdd')));

const ExerciseCategoryList = Loadable(lazy(() => import('../pages/admin/exerciseCategory/ExerciseCategoryList')));
const ExerciseCategoryEdit = Loadable(lazy(() => import('../pages/admin/exerciseCategory/ExerciseCategoryEdit')));
const ExerciseCategoryAdd = Loadable(lazy(() => import('../pages/admin/exerciseCategory/ExerciseCategoryAdd')));

const LiquidList = Loadable(lazy(() => import('../pages/admin/liquid/LiquidList')));
const LiquidEdit = Loadable(lazy(() => import('../pages/admin/liquid/LiquidEdit')));
const LiquidAdd = Loadable(lazy(() => import('../pages/admin/liquid/LiquidAdd')));

const MuscleCategoryList = Loadable(lazy(() => import('../pages/admin/muscleCategory/MuscleCategoryList')));
const MuscleCategoryEdit = Loadable(lazy(() => import('../pages/admin/muscleCategory/MuscleCategoryEdit')));
const MuscleCategoryAdd = Loadable(lazy(() => import('../pages/admin/muscleCategory/MuscleCategoryAdd')));

const HeightList = Loadable(lazy(() => import('../pages/admin/height/HeightList')));
const HeightEdit = Loadable(lazy(() => import('../pages/admin/height/HeightEdit')));
const HeightAdd = Loadable(lazy(() => import('../pages/admin/height/HeightAdd')));

const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const GeneralAdmin = Loadable(lazy(() => import('../pages/dashboard/GeneralAdmin')));
const General404 = Loadable(lazy(() => import('../pages/Page404')));
const SubscriptionSuccess = Loadable(lazy(() => import('../pages/SubscriptionSuccess')));
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const AboutPage =  Loadable(lazy(()=>import('../pages/About')))
const ContactPage =  Loadable(lazy(()=>import('../pages/Contact')))
const PrivacyPolicy =  Loadable(lazy(()=>import('../pages/PrivacyPolicy')))
const TermsAndCondition =  Loadable(lazy(()=>import('../pages/TermsAndCondition')))
const InvoiceList =  Loadable(lazy(()=>import('../pages/invoice/InvoiceList')))
const InvoiceAdd =  Loadable(lazy(()=>import('../pages/invoice/InvoiceAdd')))
const FinanceDoctorWork =  Loadable(lazy(()=>import('../pages/finance/FinanceDoctorWork')))
const FinanceStaffWork =  Loadable(lazy(()=>import('../pages/finance/FinanceStaffWork')))
const FinanceLabs =  Loadable(lazy(()=>import('../pages/finance/FinanceLabs')))
const FinanceLabOverview =  Loadable(lazy(()=>import('../pages/finance/FinanceLabOverview')))
const FinanceLabWork =  Loadable(lazy(()=>import('../pages/finance/FinanceLabWork')))
const PatientList =  Loadable(lazy(()=>import('../pages/patient/PatientList')))
const PatientAdd =  Loadable(lazy(()=>import('../pages/patient/PatientAdd')))
const PatientEdit =  Loadable(lazy(()=>import('../pages/patient/PatientEdit')))
const HomePage =  Loadable(lazy(()=>import('../pages/HomePage')))
const LabList =  Loadable(lazy(()=>import('../pages/Labaratores/LabList')))
const Subscription =  Loadable(lazy(()=>import('../pages/authentication/Subscription')))
const PatientProfile =  Loadable(lazy(()=>import('../pages/front/PatientProfile')))
const ConsaltingProfile =  Loadable(lazy(()=>import('../pages/front/Consalting')))



// front
const ClinicProfile =  Loadable(lazy(()=>import('../pages/front/ClinicProfile')))
const Clinices =  Loadable(lazy(()=>import('../pages/front/Clinices')))
const Doctors =  Loadable(lazy(()=>import('../pages/front/Doctors')))
const DoctorsInformation =  Loadable(lazy(()=>import('../pages/front/DoctorsInformation')))
