import Router from './routes';
import ThemeConfig from './theme';
import useAuth from './hooks/useAuth';
// import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import { YMInitializer } from 'react-yandex-metrika';
import { Notifications } from 'react-push-notification';

export default function App() {
  const { isInitialized } = useAuth();
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <YMInitializer accounts={[95318504]} />
              <ScrollToTop />
              <Notifications/>
              {isInitialized ? <Router /> : <LoadingScreen />}
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
