import Slider from 'react-slick';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import {styled, useTheme} from '@material-ui/core/styles';
import {Button, Card, Stack, TextField, Tooltip, Typography} from '@material-ui/core';
import { CarouselControlsArrowsIndex } from './controls';
import {fCurrency} from "../../utils/formatNumber";
import useLocales from "../../hooks/useLocales";
import useAuth from "../../hooks/useAuth";
import {UpdateInvoceAmount} from "../../utils/fakeRequest";
import {useSnackbar} from "notistack5";
import useSettings from "../../hooks/useSettings";


const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop:'30px'
});


export default function CarouselBasic1({invoice,reFache}) {
  const { numberToggle,onToggleNumberBlur } = useSettings();
  const {translate} = useLocales();
  const {user} = useAuth()
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const carouselRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(theme.direction === 'rtl' ? invoice?.length - 1 : 0);
  const [amount,setAmount] = useState('')
  const settings = {
    dots: false,
    arrows: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (current, next) => setCurrentIndex(next)
  };

  const handlePrevious = () => {
    carouselRef.current.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current.slickNext();
  };

  const handleAmount = (price,maxPrice) => {
    if(price < maxPrice){
      setAmount(price)
    }else{
      setAmount(maxPrice)
    }
  }

  const transaction = async (item) =>{

    let params ={
      inv_id:item?.inv_id,
      total:parseInt(item?.pay_amount) + parseInt(amount),
      price:amount,
      status:parseInt(item.amount) === (parseInt(item?.pay_amount) + parseInt(amount)) ?2:0,
      role:item.from === user.id ? 'partner' : 'staff'
    }

    console.log('params',params)
    await UpdateInvoceAmount(params)
    setAmount("")
    enqueueSnackbar('payed success', { variant: 'success' });
    reFache()
  }

  const handleClick = event => {
    switch (event.detail) {
      case 1: {
        break;
      }
      case 2: {
        if(numberToggle){
          setOpen(true)
        }else{
          onToggleNumberBlur()
        }

        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <Card sx={{height:'100%'}}>
      <CarouselControlsArrowsIndex
          index={currentIndex}
          total={invoice?.length}
          onNext={handleNext}
          onPrevious={handlePrevious}
      />
      <Slider ref={carouselRef} {...settings}>
        {invoice?.map((item,index) => (
            <Card sx={{ p: 3,height:'100%',mb:0,justifyContent:'space-between'}}>
              <Typography variant="subtitle2" gutterBottom>
                {item?.inv_id}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {item?.start} - {item?.finish}
              </Typography>
              <Stack spacing={2}>
                <Stack onClick={()=>handleClick()}>
                {!numberToggle && (
                    <Typography sx={{ typography: 'h3' }}>
                      <Tooltip title="for hide price duble Click">
                        <span>{fCurrency(item?.range)}֏</span>
                      </Tooltip>
                    </Typography>

                )}
                {numberToggle && (
                    <Typography sx={{ typography: 'h3' ,filter: 'blur(5px)'}}>
                      <Tooltip title="for show price duble Click">
                        <span>{fCurrency(item?.range || 0)}֏</span>
                      </Tooltip>
                    </Typography>
                )}
                </Stack>

                <RowStyle>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {translate('app.totalAmount')}
                  </Typography>
                  <Typography variant="subtitle1">{fCurrency(item?.amount || 0)}֏</Typography>
                </RowStyle>
                <RowStyle>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {translate('app.amountPaid')}
                  </Typography>
                  <Typography variant="body2">{fCurrency(item?.pay_amount || 0)}֏</Typography>
                </RowStyle>

                <Stack direction="column"  spacing={2}>
                  <TextField
                      sx={{mt:2}}
                      fullWidth
                      type={'number'}
                      label={translate('app.price')}
                      value={amount}
                      onChange={ e =>handleAmount(e.target.value,item?.range)}
                  />
                  <Button onClick={()=>transaction(item)} variant="contained" color="warning">
                    {translate('app.pay')}
                  </Button>
                </Stack>
              </Stack>
            </Card>
        ))}
      </Slider>
    </Card>
  );
}
