import PropTypes from 'prop-types';
// material
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 40, height: 24, ...sx }}>
        {/*<defs>*/}
        {/*  <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">*/}
        {/*    <stop offset="0%" stopColor={PRIMARY_DARK} />*/}
        {/*    <stop offset="100%" stopColor={PRIMARY_MAIN} />*/}
        {/*  </linearGradient>*/}
        {/*  <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">*/}
        {/*    <stop offset="0%" stopColor={PRIMARY_LIGHT} />*/}
        {/*    <stop offset="100%" stopColor={PRIMARY_MAIN} />*/}
        {/*  </linearGradient>*/}
        {/*  <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">*/}
        {/*    <stop offset="0%" stopColor={PRIMARY_LIGHT} />*/}
        {/*    <stop offset="100%" stopColor={PRIMARY_MAIN} />*/}
        {/*  </linearGradient>*/}
        {/*</defs>*/}

          {/*<svg fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">*/}
      <svg width="100%" height="100%" viewBox="0 0 384 384" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2_10)">
          <rect width="384" height="384" rx="85" fill="url(#paint0_linear_2_10)"/>
          <g filter="url(#filter0_di_2_10)">
            <path d="M186.007 58H180.299L50 284.007L53.0224 288.373L101.381 260.164L125.896 217.851L117.5 213.149L150.41 156.731L158.134 161.769L186.007 114.754V58Z" fill="white"/>
            <path d="M198.433 58H204.142L334.44 284.007L331.418 288.373L283.06 260.164L258.545 217.851L266.94 213.149L234.03 156.731L226.306 161.769L198.433 114.754V58Z" fill="white"/>
            <path d="M275.672 271.918H107.425L58.3955 300.127L63.097 305.5H133.955L154.104 293.41H238.06L257.201 305.5H321.343L325.373 300.127L275.672 271.918Z" fill="white"/>
          </g>
        </g>
        <defs>
          <filter id="filter0_di_2_10" x="-198" y="-128" width="780.44" height="743.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="62"/>
            <feGaussianBlur stdDeviation="124"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.533333 0 0 0 0 0.596078 0 0 0 0 0.654902 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_10"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_10" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="3"/>
            <feGaussianBlur stdDeviation="2.5"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.478431 0 0 0 0 1 0 0 0 0.3 0"/>
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2_10"/>
          </filter>
          <linearGradient id="paint0_linear_2_10" x1="192" y1="5.72205e-06" x2="192" y2="384" gradientUnits="userSpaceOnUse">
            <stop stopColor="#8AC2FF"/>
            <stop offset="1" stopColor="#007AFF"/>
          </linearGradient>
          <clipPath id="clip0_2_10">
            <rect width="384" height="384" rx="85" fill="white"/>
          </clipPath>
        </defs>
      </svg>


    </Box>
  );
}



