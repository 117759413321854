import { format, getTime, formatDistanceToNow } from 'date-fns';
import {useMemo} from "react";

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}
export function fTime(date) {
    const currentDate = new Date(date);
    const hours = currentDate.getUTCHours();
    const minutes = currentDate.getUTCMinutes();

    // Formatting to ensure two digits for hours and minutes
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes}`;
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fDateTimeCountDown(date) {

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${month}.${day}.${year}\n ${hours}:${minutes}`;

}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function convertAmPmTo24Hour(timeString) {
    if (/^\d{1,2}:\d{2} (am|pm)$/i.test(timeString)) {
        const [time, period] = timeString.split(' ');
        let [hours, minutes] = time.split(':').map(Number);

        console.log('time',time,'period',period,'hours',hours,minutes,minutes)


        if (period.toLowerCase() === 'am' && hours === 12) {
            hours = 0;
        } else if (period.toLowerCase() === 'pm' && hours !== 12) {
            hours += 12;
        }

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const convertedTime = `${formattedHours}:${formattedMinutes}`;

        return convertedTime;
    } else {
        return 'Invalid input format';
    }
}

export const translateFormatDate = (date) =>{
    const currentDate = new Date(date);
    const armenianMonthNames = [
        "Հուն.",
        "Փետ.",
        "Մար.",
        "Ապր.",
        "Մայ.",
        "Հուն.",
        "Հուլ.",
        "Օգ.",
        "Սեպ.",
        "Հոկ.",
        "Նոյ.",
        "Դեկ."
    ];
    const currentMonthIndex = currentDate.getMonth();
    const currentMonthNameArmenian = armenianMonthNames[currentMonthIndex];
    const currentYear = currentDate.getFullYear();
    // ${currentYear}
    return ` ${currentMonthNameArmenian} ${currentDate.getDate()}`;

}

export function fDateTimeTask(date) {
    const armenianMonthNames = [
        "Հուն.",
        "Փետ.",
        "Մար.",
        "Ապր.",
        "Մայ.",
        "Հուն.",
        "Հուլ.",
        "Օգ.",
        "Սեպ.",
        "Հոկ.",
        "Նոյ.",
        "Դեկ."
    ];
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${armenianMonthNames[date.getMonth()]} ${day} ${hours}:${minutes}`;

}

export const  getStartAndFinishDates =(inputDate)=> {
    const startDate = new Date(inputDate);
    startDate.setDate(1);
    const lastDayOfMonth = new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        0
    ).getDate();
    const finishDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        lastDayOfMonth
    );
    return {
        start: startDate,
        finish: finishDate,
    };
}


export const GetYearMont = () =>{
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const formattedMonth = currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`;
    return `${currentYear}${formattedMonth}`;
}


export const getMonthName =  (yearAndMonth) => {

    const year = yearAndMonth?.substring(0, 4);
    const month = yearAndMonth?.substring(4);
    const armenianMonthNames = [
        'Հունվար',
        'Փետրվար',
        'Մարտ',
        'Ապրիլ',
        'Մայիս',
        'Հունիս',
        'Հուլիս',
        'Օգոստոս',
        'Սեպտեմբեր',
        'Հոկտեմբեր',
        'Նոյեմբեր',
        'Դեկտեմբեր',
    ];

    return armenianMonthNames[month-1];
}

export  const calculateAge = (birthdate) => {
    // Parse the birthdate string to a Date object
    const birthdateDate = new Date(birthdate);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in years
    const age = currentDate.getFullYear() - birthdateDate.getFullYear();

    // Adjust the age if the birthday hasn't occurred yet this year
    if (
        currentDate.getMonth() < birthdateDate.getMonth() ||
        (currentDate.getMonth() === birthdateDate.getMonth() &&
            currentDate.getDate() < birthdateDate.getDate())
    ) {
        return age - 1;
    } else {
        return age;
    }
}
