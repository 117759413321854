// material
import { alpha, useTheme, styled } from '@material-ui/core/styles';
import { Box, Grid, Card, Container, Typography, useMediaQuery } from '@material-ui/core';
//
import {varFadeInUp, MotionInView, varFadeInDown, varFadeInRight} from '../../animate';
import useLocales from "../../../hooks/useLocales";

// ----------------------------------------------------------------------

const shadowIcon = (color) => `drop-shadow(2px 2px 2px ${alpha(color, 0.48)})`;

const RootStyle = styled('div')(({ theme }) => ({
    paddingTop: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(15)
    }
}));

const CardStyle = styled(Card)(({ theme }) => {
    const shadowCard = (opacity) =>
        theme.palette.mode === 'light'
            ? alpha(theme.palette.grey[500], opacity)
            : alpha(theme.palette.common.black, opacity);

    return {
        maxWidth: 200,
        minHeight: 100,
        margin: 'auto',
        textAlign: 'center',
        padding: theme.spacing(5, 1, 0),
        boxShadow: `-40px 40px 80px 0 ${shadowCard(0.48)}`,
        [theme.breakpoints.up('md')]: {
            boxShadow: 'none',
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
        },
        '&.cardLeft': {
            [theme.breakpoints.up('md')]: { marginTop: -40 }
        },
        '&.cardCenter': {
            [theme.breakpoints.up('md')]: {
                marginTop: -80,
                backgroundColor: theme.palette.background.paper,
                boxShadow: `-40px 40px 80px 0 ${shadowCard(0.4)}`,
                '&:before': {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: -1,
                    content: "''",
                    margin: 'auto',
                    position: 'absolute',
                    width: 'calc(100% - 40px)',
                    height: 'calc(100% - 40px)',
                    borderRadius: theme.shape.borderRadiusMd,
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: `-20px 20px 40px 0 ${shadowCard(0.12)}`
                }
            }
        }
    };
});

const CardIconStyle = styled('img')(({ theme }) => ({
    width: 60,
    height: 40,
    margin: 'auto',
    objectFit:'cover',
    marginBottom: theme.spacing(4),
    filter: shadowIcon(theme.palette.primary.main)
}));

// ----------------------------------------------------------------------

export default function LandingLanguages() {
    const {translate} = useLocales();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const CARDS = [
        {
            icon: '/static/icons/ic_flag_en.svg',
            title: translate('app.english'),
        },
        {
            icon: '/static/icons/ic_flag_de.svg',
            title: translate('app.german'),
        },
        {
            icon: '/static/icons/ic_flag_fr.svg',
            title: translate('app.french'),
        },
        {
            icon: '/static/icons/ic_flag_hy.svg',
            title: translate('app.armenian'),
        },
        {
            icon: '/static/icons/ic_flag_es.svg',
            title: translate('app.spanish'),
        },
    ];

    return (
        <RootStyle>
            <Container maxWidth="lg">
                <Box  sx={{ mb: { xs: 5, md: 12 } }}>
                    <MotionInView variants={varFadeInDown}>
                        <Typography variant="h3" sx={{ textAlign: 'center' }}>
                            {translate('app.availableLanguages')}
                        </Typography>
                    </MotionInView>
                </Box>
                <Grid container sx={{justifyContent:'center'}} spacing={isDesktop ? 5 : 5}>
                    {CARDS.map((card, index) => (
                        <Grid item key={card.title}  xs={6} md={2}>
                            <MotionInView variants={varFadeInRight}>
                                <CardStyle>
                                    <CardIconStyle
                                        src={card.icon}
                                        alt={card.title}
                                    />
                                    <Typography variant="h6" paragraph>
                                        {card.title}
                                    </Typography>
                                </CardStyle>
                            </MotionInView>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </RootStyle>
    );
}
