import React, {useEffect, useState} from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import {API_URL} from "../config";
import axios from "axios";
import { loadStripe } from '@stripe/stripe-js';
import useAuth from "../hooks/useAuth";
import {CreateSubscription} from "../utils/fakeRequest";
import {Button} from "@mui/material";

const stripePromise = loadStripe('pk_live_51MxbYjA2EpIxWhOLdpwt69VZ5qu33KHRt5DtDj4xbxLJO7tueW657032ECkQWOXoZQHdFxALpfhOEMvNGhTfnYJ7000282ksG9');
const StripeButtonPay = ({productId,title}) => {
    const {user} = useAuth()

    const handleClick = async () => {
        const stripe = await stripePromise;
        let data = {
            items: [{price: productId, quantity: 1}],
            userId:user?.id,
        }
        const session = await CreateSubscription(data)
        await stripe.redirectToCheckout({
            sessionId: session.data.id,
        });
    };

    return (
    <Button
        onClick={handleClick}
        size="large"
        variant="contained"
        sx={{
            whiteSpace: 'nowrap',
            boxShadow: (theme) => theme.customShadows.z8,
            color: (theme) => theme.palette.getContrastText(theme.palette.common.white),
            bgcolor: 'common.white',
            '&:hover': { bgcolor: 'grey.300' }
        }}
    >
        {title}
    </Button>
    );
};

export default StripeButtonPay
