export const firebaseConfig = {
  apiKey: 'AIzaSyDHv2MCkaQPrFPvDaQsIAgQnDvEOxB5hrk',
  authDomain: 'agilemedix-26fa5.firebaseapp.com',
  // databaseURL: 'agilemedix-26fa5',
  projectId: 'agilemedix-26fa5',
  storageBucket: 'agilemedix-26fa5.appspot.com',
  messagingSenderId: '625377505969',
  appId: '1:625377505969:web:3d5071bc672e2b9cf9e890',
  measurementId: 'G-1ZN46BXF9H'
};


export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};




export const mapConfig = 'pk.eyJ1IjoiaGF5azk1OTUiLCJhIjoiY2thODM0d2hxMDk5djJ6cGN1dWFkMjNkbSJ9.-tYQ8IHHycal5KCtiuqraQ';

export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const API_URL = 'https://api.agilemedix.com/api/v1';
export const API_WS = 'https://api.agilemedix.com'
export const IMAGE_URL = 'https://api.agilemedix.com/images';

// export const API_WS = 'http://192.168.10.10:4001'
// export const API_URL = 'http://192.168.10.10:4001/api/v1';
// export const IMAGE_URL = 'http://192.168.10.10:4001/images';



