import { motion } from 'framer-motion';

import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
// material
import {alpha, styled, useTheme} from '@material-ui/core/styles';
import {Grid, Link, Divider, Container, Typography, IconButton, Stack, useMediaQuery} from '@material-ui/core';
// routes
import { PATH_PAGE } from '../../routes/paths';
//
import Logo from '../../components/Logo';
import instagramFilled from "@iconify/icons-ant-design/instagram-filled";
import useLocales from "../../hooks/useLocales";
import {FacebookOutlined, Instagram, LinkedIn, Telegram} from "@material-ui/icons";
import {varFadeInRight} from "../../components/animate";

// ----------------------------------------------------------------------

const SOCIALS = [
  { name: 'FaceBook', icon: <FacebookOutlined/>,link:'https://www.facebook.com/profile.php?id=61552278158880'},
  { name: 'Instagram', icon: <Instagram/> ,link:'https://www.instagram.com/agilemedix'},
  { name: 'Linkedin', icon: <LinkedIn/>,link:'https://www.linkedin.com/company/97459772' },
  { name: 'telegram', icon: <Telegram/> },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

const shadowIcon = (color) => `drop-shadow(2px 2px 2px ${alpha(color, 0.48)})`;
const LogoStyle = styled('img')(({ theme }) => ({
  textAlign:'center',
  justifyContent:'center',
  filter: shadowIcon(theme.palette.primary.main)
}));
// ----------------------------------------------------------------------

export default function MainFooter() {
  const {translate} = useLocales();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const PAY_METOD = [
    { name: 'Amrician express', icon: 'amex.svg' },
    { name: 'Master card', icon: 'mastercard.svg' },
    { name: 'Visa', icon: 'visa.svg' },
    { name: 'Apple Pay', icon: 'applePay.svg' },
    { name: 'Google Pay', icon: 'googlePay.svg' }
  ]

  const LINKS = [
    {
      headline: translate('app.company'),
      children: [
        { name: translate('app.aboutUs'), href: PATH_PAGE.about },
        { name: translate('app.contactUs'), href: PATH_PAGE.contact },
        { name: translate('app.termsAndCondition'), href: PATH_PAGE.termsAndCondition },
        { name: translate('app.privacyPolicy'), href: PATH_PAGE.privacyPolicy }
      ]
    },
    {
      headline: translate('app.contact'),
      children: [
        { name: 'agilemedix@gmail.com', href: 'mailto:agilemedix@gmail.com' },
        { name: '+374 44 410 466', href: 'tel:+374 44 410 466' }
      ]
    }
  ];
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ py: 5 }}>
        <Grid
          container
          justifyContent={{ xs: 'left', md: 'space-between' }}
          sx={{ textAlign: { xs: 'left', md: 'left' } }}
        >
          <Grid item xs={12} md={3}>
            <ScrollLink to="move_top" spy smooth>
              <LogoStyle sx={{width:'100%'}}  src={"/static/home/logo.png"} alt={"logo"}/>
            </ScrollLink>
            <Typography textAlign={'justify'} sx={{fontSize:'9px' ,mt:'10px'}}>
              {translate('app.footerText')}
            </Typography>
            <Stack
              spacing={1.5}
              direction="row"
              justifyContent={{ xs: 'space-between', md: 'space-between' }}
              sx={{ mt: 1, mb: { xs: 5, md: 0 } }}
            >
              {SOCIALS.map((social,index) => (
                <IconButton target={'_blank'} href={social.link} key={index} color="primary" sx={{ p: 1 }}>
                  {social.icon}
                </IconButton>
              ))}
            </Stack>
            {/*<Stack sx={{mt:1,mb:{xs: 3}}}  direction="row" justifyContent={{ xs: 'center', md: 'space-between' }}>*/}
            {/*  <motion.img width={'50%'} style={{marginRight:'10px'}}  variants={varFadeInRight} src="/static/home/app-store.png" />*/}
            {/*  <motion.img width={'50%'}  variants={varFadeInRight} src="/static/home/google-play.png" />*/}
            {/*</Stack>*/}
          </Grid>
          <Grid item xs={12} md={2}>
            <Stack sx={{mb: {xs: 3}}} spacing={2}>
              <Typography component="p" variant="overline">
                {translate('app.paymentMethods')}
              </Typography>
              <Stack gap={2} flexWrap={{ xs: 'nowrap', md: 'wrap' }} direction={{ xs: 'row', md: 'row' }}>
                {PAY_METOD.map((item,index)=>(
                    <motion.img key={index} width={'25%'} variants={varFadeInRight} src={`/static/icons/${item.icon}`} />
                ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list,index) => {
                const { headline, children } = list;
                return (
                  <Stack key={index} spacing={2}>
                    <Typography component="p" variant="overline">
                      {headline}
                    </Typography>
                    {children.map((link) => (
                      <Link
                        to={link.href}
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        component={RouterLink}
                        sx={{ display: 'block' }}
                      >
                        {link.name}
                      </Link>
                    ))}
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <Typography component="p" variant="body2" sx={{
            py:3,
            fontSize: { xs: 11, md: 13 },
            textAlign: { xs: 'center', md: 'center' }
          }}>
        © {translate('app.copyrite')}
      </Typography>
    </RootStyle>
  );
}
