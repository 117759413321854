import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// material
import {alpha, styled, useTheme} from '@material-ui/core/styles';
import {Box, Button, AppBar, Toolbar, Container, useMediaQuery, Stack, Link} from '@material-ui/core';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
import { MHidden } from '../../components/@material-extend';
//
// import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import useLocales from "../../hooks/useLocales";
import {PATH_AUTH} from "../../routes/paths";
import LanguagePopover from "../dashboard/LanguagePopover";
import MenuDesktop from "./MenuDesktop";
import PatientSignupDialog from "../../components/auth/PatientSignupDialog";
import {useState} from "react";
import AccountPopover from "../dashboard/AccountPopover";
import useAuth from "../../hooks/useAuth";
import NotificationsPopover from "../dashboard/NotificationsPopover";
import PatientNotification from "../dashboard/PatientNotification";
import SubscribeForm from "../../components/SubscribeForm";
import useSettings from "../../hooks/useSettings";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 64;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
    borderBottom:'1px solid #00000010',
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

const shadowIcon = (color) => `drop-shadow(2px 2px 2px ${alpha(color, 0.48)})`;
const LogoStyle = styled('img')(({ theme }) => ({
  filter: shadowIcon(theme.palette.primary.main)
}));

// ----------------------------------------------------------------------

export default function MainNavbar({notCover,business}) {
    const { readText, onChangeRead } = useSettings();

  const isOffset = notCover ? notCover : useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const {translate} = useLocales();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openAuth, setOpenAuth] = useState(false)
  const [infoModal,setInfoModal] = useState(true);
    const {user} = useAuth()



  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/">
            <LogoStyle sx={{width:isMobile?'40px':'40px'}}  src={!isOffset?"/static/home/smallLogoBlue.png":"/static/home/smallLogoBlue.png"} alt={"logo"}/>
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />
           <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
           </MHidden>
          <LanguagePopover />
            {!user && (
                <Stack sx={{ml:2}} gap={1} direction={'row'} alignItems={'center'}>
                    {business && (
                        <Link
                            to={PATH_AUTH.login}
                            component={RouterLink}
                        >
                            {/*<Label variant={'filled'}  color={'secondary'} >*/}
                            {/*  */}
                            {/*</Label>*/}
                            <Button size={'small'} variant={'outlined'}>  {translate('app.signIn')}</Button>
                        </Link>
                    )}
                    {!business && (
                        <Link
                            to={PATH_AUTH.login}
                            component={RouterLink}
                        >
                            {/*<Label variant={'filled'}  color={'secondary'} >*/}
                            {/*  */}
                            {/*</Label>*/}
                            <Button size={'small'} variant={'outlined'}>  {translate('app.signIn')}</Button>
                        </Link>
                    )}
                    <PatientSignupDialog open={openAuth} close={setOpenAuth}/>

                </Stack>
            )}
            {user && (
                <>
                  {user.rolle === 'patient' && (
                        <PatientNotification/>
                  )}
                  {user.rolle !== 'patient' && (
                        <NotificationsPopover/>
                  )}
                  <AccountPopover />
                </>
            )}


          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      <SubscribeForm personalModal={readText} setPersonalModal={onChangeRead}/>
      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
