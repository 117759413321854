import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import {useEffect, useRef, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
// material
import { alpha } from '@material-ui/core/styles';
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton
} from '@material-ui/core';
// utils
// import mockData from '../../utils/mock-data';
import {fDateTime, fToNow} from '../../utils/formatTime';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';
import useAuth from "../../hooks/useAuth";
import {GetConsulting} from "../../utils/fakeRequest";
import {IMAGE_URL} from "../../config";
import useNotification from "../../hooks/useNotification";
import useAudio from "../../hooks/useAudio";
import NotificationSound from "../../assets/sound/sound.mp3";
import useLocales from "../../hooks/useLocales";

// ----------------------------------------------------------------------

const TITLES = [
  'Your order is placed',
  'Sylvan King',
  'You have new message',
  'You have new mail',
  'Delivery processing'
];

const DESCRIPTIONS = [
  'waiting for shipping',
  'answered to your comment on the Minimal',
  '5 unread messages',
  'sent from Guido Padberg',
  'Your order is being shipped'
];

const TYPES = ['order_placed', 'friend_interactive', 'chat_message', 'mail', 'order_shipped'];

const UNREADS = [true, true, false, false, false];
const MOCK_NOTIFICATIONS = [];

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.name}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.comment)}
      </Typography>
    </Typography>
  );

  if (notification.doctor_status === 'unread') {
    return {
      avatar: <img alt={notification?.name} src={`${IMAGE_URL}/staff/${notification?.image}`} />,
      title
    };
  }
  if (notification.doctor_status === 'read') {
    return {
      avatar: <img alt={notification?.name} src={`${IMAGE_URL}/staff/${notification?.image}`} />,
      title
    };
  }
  return {
    avatar: <img alt={notification.title} src={notification.avatar} />,
    title
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired
};

function NotificationItem({ notification,handelStatus }) {

  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      // to="#"
      // disableGutters
      // component={RouterLink}
      onClick={()=>handelStatus(1,notification)}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.status === 0 && {
          bgcolor: 'action.selected'
        })
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
            {/*{fToNow(notification.date)}*/}
          </Typography>
        }
      />
      <ListItemText
          primary={fDateTime(notification?.book_day)}
          secondary={
            <Typography
                variant="caption"
                sx={{
                  mt: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  color: 'text.disabled'
                }}
            >
              <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
              {/*{fToNow(notification.date)}*/}
            </Typography>
          }
      />
    </ListItemButton>
  );
}

export default function NotificationsPopover() {
  const {user} = useAuth()
    const {translate} = useLocales();
  const { isPlaying, play } = useAudio();
  const { socket, status, notifications,setSound,  unreadMessages,updateConsalting,sound } = useNotification(user);

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const totalUnRead = notifications.filter((item) => item.doctor_status === 'unread').length;

  useEffect(() => {
   if(socket){


     console.log('unreadMessages',unreadMessages)
   }
  }, [socket]);


  const handleOpen = () => {
    setOpen(true);

  };

  useEffect(() => {
    // console.log('sound', sound);

    if (sound) {
      play(NotificationSound);
      setTimeout(() => {
        setSound(false);
      }, 2000);
    }
  }, [sound, play]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllAsRead = () => {
    // markMessagesAsRead()
  };

  const handelStatus = (status,item) =>{
    console.log('asfdsf',item)
    if(user.rolle === "Doctor"){
      let targetID = `${item.patient_id}_patient`
      let data = {...item,status:status,doctor_status:'read',patient_status:'unread'}

      console.log('targetID',targetID)
      console.log('data',data)
      updateConsalting(targetID,data)
    }

  }


  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{translate('app.notifications')}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {translate('app.you_have')} {totalUnRead} {translate('app.unread_messages')}
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <MIconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            // subheader={
            //   <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
            //     New
            //   </ListSubheader>
            // }
          >
            {notifications.map((notification) => (
              <NotificationItem handelStatus={handelStatus} key={notification.id} notification={notification} />
            ))}
          </List>
        </Scrollbar>

        <Divider />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component={RouterLink} to="#">
              {translate('app.viewAll')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
