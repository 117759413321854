
import { motion } from 'framer-motion';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import {styled, useTheme} from '@material-ui/core/styles';
import {Box, Container, Typography, Stack, useMediaQuery} from '@material-ui/core';
import { varFadeIn, varFadeInUp, varWrapEnter, varFadeInRight } from '../animate';
import {useEffect, useState} from "react";
import useLocales from "../../hooks/useLocales";
import {GetHomeServices, GetServices} from "../../utils/fakeRequest";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {PATH_PAGE} from "../../routes/paths";

// ----------------------------------------------------------------------

const RootStyle = styled(motion.div)(({ theme }) => ({
    position: 'relative',
    // backgroundColor: theme.palette.grey[400],
    [theme.breakpoints.up('md')]: {
        top: 0,
        left: 0,
        width: '100%',
        // height: '100vh',
        display: 'flex',
        // alignItems: 'center'
    }
}));

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(({ theme }) => ({
    zIndex: 10,
    maxWidth: 520,
    // margin: 'auto',
    textAlign: 'center',
    position: 'relative',
    // paddingTop: theme.spacing(15),
    // paddingBottom: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
        margin: 'unset',
        textAlign: 'left'
    }
}));

const HeroOverlayStyle = styled(motion.img)({
    zIndex: 9,
    width: '100%',
    height: '100vh',
    // objectFit: 'cover',
    position: 'absolute'
});

const HeroImgStyle = styled(motion.img)(({ theme }) => ({
    // top: 0,
    // right: 0,
    // left:0,
    // bottom: 0,
    // zIndex: 8,
    // width: '100%',
    // margin: 'auto',
    // position: 'absolute',
    [theme.breakpoints.up('lg')]: {
        // right: '8%',
        // top: 0,
        // right: 0,
        // left:0,
        // bottom: 0,
        // width: '100%',
        // objectFit:'cover',
        // height: '100vh'
    }
}));

// ----------------------------------------------------------------------

export default function HeroHome({searching}) {
    const theme = useTheme();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {translate} = useLocales();

    const handleOnSearch = (string, results) => {
        console.log(string, results)
    }

    const handleOnHover = (result) => {
        // the item hovered
        console.log(result)
    }

    const handleOnSelect = (item) => {
        // the item selected

        //
        if(item.type === 'service'){
            navigate(`${PATH_PAGE.doctorsList}?filters=${item.id}`)
        }else{
            navigate(`${PATH_PAGE.doctorsList}/${item.id}`)
        }
        console.log('select',item)
    }

    const handleOnFocus = () => {
        console.log('Focused')
    }

    const formatResult = (item) => {
        if(item.type === 'service'){
            return (<span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>)
        }else if(item.type === 'doctor') {
            return (<span style={{ display: 'block', textAlign: 'left' }}>{translate('app.doctor')} {item.name}</span>)
        }


    }


    return (
        <>

            <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
                <Container maxWidth="lg">
                    {/*<HeroImgStyle alt="hero" src="/static/home/coverHome.webp" variants={varFadeIn} />*/}
                    <Box
                        sx={{
                            // backgroundImage:!isMobile && `url(${'/static/home/happy.webp'})`,
                            // backgroundRepeat: "no-repeat",
                            // backgroundSize: "contain",
                            backgroundPosition:"right",
                        }}
                    >
                        <ContentStyle>

                            <Stack sx={{marginTop:theme.spacing(isMobile?1:5),height:'30vh',justifyContent:'center'}}>
                                <motion.div variants={varFadeInRight}>
                                    <Typography variant="h4" sx={{color:'primary.main'}}>
                                        {translate('app.patentHomeInfo')}
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ mb:2,color:'#838181' }}>
                                        {translate('app.homePageSubtitle')}
                                    </Typography>
                                </motion.div>
                                <motion.div variants={varFadeInRight}>
                                    <ReactSearchAutocomplete
                                        items={searching}
                                        onSearch={handleOnSearch}
                                        onHover={handleOnHover}
                                        onSelect={handleOnSelect}
                                        onFocus={handleOnFocus}
                                        autoFocus
                                        formatResult={formatResult}
                                        placeholder={translate('app.search')}
                                    />
                                </motion.div>
                            </Stack>
                        </ContentStyle>
                        </Box>

                </Container>
            </RootStyle>
        </>
    );
}
