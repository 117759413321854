import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/authentication/Login';
import Page404 from '../pages/Page404';
import HomePage from '../pages/HomePage';
import SubscriptionSuccess from "../pages/SubscriptionSuccess";

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, user } = useAuth();
  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);


  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }else{
    // if(user && user?.rolle === 'patient' && array[1] === 'dashboard'){
    //   window.location.href='/'
    // }
  }

  let array = pathname.split('/')
  if(array[1] === 'dashboard' && array[2] === 'success'){

    return <SubscriptionSuccess/>
  }



  if(array[1] === 'dashboard' && user?.rolle === 'patient'){
    return <Page404/>
  }

if(!user.subscription){
  const acssesPageDoctor = ['dashboard/overview','dashboard/success','dashboard/service','dashboard/profile','dashboard']
  const isAccessGranted = acssesPageDoctor.some((path) => location.pathname.endsWith(path));
  if(isAccessGranted){
    return <>{children}</>;
  }else{
    return <Page404/>
  }
}else{
  return <>{children}</>;
}








}
