import { useEffect, useState } from 'react';
import {Stack,Card, Box,Typography} from '@material-ui/core';
import useLocales from "../../hooks/useLocales";
import {Button} from "@mui/material";
import {Block} from "../../pages/components-overview/Block";

// ----------------------------------------------------------------------

export default function SelectType({getTypes}) {
    const {translate} = useLocales();
    const [role, setRole] = useState(null);

    const handleNext = ()=>{
        console.log('role',role)
        getTypes(role)
    }
    return (
        <Stack spacing={3}>
            <Block title={translate('app.accountTypeSelection')}>
                <Stack sx={{flexDirection: {md:'row', xs: 'column'},gap:'10px'}} >
                    {/*<Card onClick={()=>setRole('patient')} sx={{border:role === 'patient'?'1px solid #3366FF':'1px solid #fff',py:2,width:'100%',flexDirection:'column' ,display:'flex',alignItems:'center',justifyContent: 'center',cursor:'pointer'}}>*/}
                    {/*    <Box component="img" src={`/static/auth/userIcon.webp`} alt={'clicic'} sx={{ width: 60, height: 60 }} />*/}
                    {/*    <Typography mt={2} variant="body2">{translate('app.patient')}</Typography>*/}
                    {/*</Card>*/}
                    <Card onClick={()=>setRole('Doctor')} sx={{border:role === 'Doctor'?'1px solid #3366FF':'1px solid #fff',py:2,width:'100%',flexDirection:'column' ,display:'flex',alignItems:'center',justifyContent: 'center',cursor:'pointer'}}>
                        <Box component="img" src={`/static/auth/doctor.svg`} alt={'clicic'} sx={{ width: 60, height: 60 }} />
                        <Typography mt={2} variant="body2">{translate('app.doctor')}</Typography>
                    </Card>
                    {/*<Card onClick={()=>setRole('business_clinic')} sx={{ border:role === 'business_clinic'?'1px solid #3366FF':'1px solid #fff',py:2,width:'100%',flexDirection:'column' ,display:'flex',alignItems:'center',justifyContent: 'center',cursor:'pointer'}}>*/}
                    {/*    <Box component="img" src={`/static/auth/clinc.svg`} alt={'clicic'} sx={{ width: 60, height: 60}} />*/}
                    {/*    <Typography mt={2} variant="body2">{translate('app.clinic')}</Typography>*/}
                    {/*</Card>*/}
                    <Card onClick={()=>setRole('business_lab')} sx={{border:role === 'business_lab'?'1px solid #3366FF':'1px solid #fff',py:2, width:'100%',flexDirection:'column' ,display:'flex',alignItems:'center',justifyContent: 'center',cursor:'pointer'}}>
                        <Box component="img" src={`/static/auth/lab.svg`} alt={'clicic'} sx={{ width: 60, height: 60 }} />
                        <Typography mt={2} variant="body2">{translate('app.lab')}</Typography>
                    </Card>
                </Stack>
                <Button  disabled={role ? false:true} sx={{mt:3}} fullWidth size="large" type="submit" variant="contained" onClick={()=>handleNext()}>
                    {translate('app.next')}
                </Button>
            </Block>
        </Stack>
    );
}
