// routes
import { PATH_ADMIN_DASHBOARD, PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';
import useAuth from '../../hooks/useAuth';
import HomeIcon from "@material-ui/icons/Home";
import {
  CalendarToday, CommentBankSharp,
  MedicalServices,
  MedicalServicesTwoTone,
  PeopleSharp,
  VerifiedUserSharp
} from "@material-ui/icons";

// ----------------------------------------------------------------------
const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '24px', height: '24px' }} />
);

const getNavIcon = (name) => (
    <img src={`/static/icon_home/${name}.svg`} />
);

const ICONS = {
  home:getNavIcon('home'),
  calendar:getNavIcon('calendar'),
  doctors:getNavIcon('doctors'),
  finance:getNavIcon('finance'),
  service:getNavIcon('lab_service'),
  other_finance:getNavIcon('other_finance'),
  skill:getNavIcon('skill'),
  staff:getNavIcon('staff'),

  dashboard: getIcon('ic_home'),
  user: getIcon('ic_user'),
  group: getIcon('ic_group'),
  trainer: getIcon('ic_trainer'),
  exercises: getIcon('ic_exercises'),
  workout: getIcon('ic_workout'),
  meals: getIcon('ic_meals'),
  programs: getIcon('ic_programs'),
  settings: getIcon('ic_settings')
};

const sidebarConfigAdmin = [
  {
    subheader: 'menu',
    items: [
      { title: 'Overview', path: PATH_ADMIN_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'Partners', path: PATH_ADMIN_DASHBOARD.partner.list, icon: ICONS.user },
      {
        title: 'Categories',
        path: '',
        icon: ICONS.settings,
        children: [
          { title: 'Food', path: PATH_ADMIN_DASHBOARD.food.list, icon: ICONS.settings },
          { title: 'Foods Type', path: PATH_ADMIN_DASHBOARD.foodType.list, icon: ICONS.settings },
          { title: 'Trainer Type', path: PATH_ADMIN_DASHBOARD.trainerType.list, icon: ICONS.settings },
          { title: 'Liquid ', path: PATH_ADMIN_DASHBOARD.liquid.list, icon: ICONS.settings },
          {
            title: 'Exercise Category',
            path: PATH_ADMIN_DASHBOARD.exerciseCategory.list,
            icon: ICONS.settings
          },
          { title: 'Muscle Category', path: PATH_ADMIN_DASHBOARD.muscleCategory.list, icon: ICONS.settings },
          { title: 'Liquid Category', path: PATH_ADMIN_DASHBOARD.liquidCategory.list, icon: ICONS.settings },
          { title: 'Weight', path: PATH_ADMIN_DASHBOARD.weight.list, icon: ICONS.settings },
          { title: 'Target', path: PATH_ADMIN_DASHBOARD.target.list, icon: ICONS.settings },
          { title: 'User Level', path: PATH_ADMIN_DASHBOARD.userLevel.list, icon: ICONS.settings },
          { title: 'Activity Level', path: PATH_ADMIN_DASHBOARD.activityLevel.list, icon: ICONS.settings },
          { title: 'Age Range', path: PATH_ADMIN_DASHBOARD.ageRange.list, icon: ICONS.settings },
          { title: 'BMI Range', path: PATH_ADMIN_DASHBOARD.bmiRange.list, icon: ICONS.settings },
          { title: 'Height', path: PATH_ADMIN_DASHBOARD.height.list, icon: ICONS.settings }
        ]
      }
    ]
  }
];

const sidebarConfig = [
  {
    subheader: 'app.menu',
    items: [
      { title: 'app.overview', path: PATH_DASHBOARD.general.app, icon: ICONS.home,show:true},
      { title: 'app.staff', path: PATH_DASHBOARD.staff.list, icon: ICONS.staff,show:true },
      { title: 'app.doctors', path: PATH_DASHBOARD.doctors.list, icon: ICONS.doctors,show:true},
      { title: 'app.schedule', path: PATH_DASHBOARD.schedule.root, icon: ICONS.calendar,show:true },
      { title: 'app.services', path: PATH_DASHBOARD.service.list, icon: ICONS.service,show:true},
      { title: 'app.skill', path: PATH_DASHBOARD.skill.list, icon: ICONS.skill,show:true },
      // { title: 'app.otherExpenses', path: PATH_DASHBOARD.otherExpenses.list, icon: ICONS.other_finance },
      { title: 'app.finance', path: PATH_DASHBOARD.finance.root, icon: ICONS.finance,show:true },
    ]
  }
];
const sidebarConfigNot = [
  {
    subheader: 'app.menu',
    items: [
      { title: 'app.overview', path: PATH_DASHBOARD.general.app, icon: ICONS.home,show:true},
      { title: 'app.staff', path: PATH_DASHBOARD.staff.list, icon: ICONS.staff ,show:false},
      { title: 'app.doctors', path: PATH_DASHBOARD.doctors.list, icon: ICONS.doctors,show:false},
      { title: 'app.schedule', path: PATH_DASHBOARD.schedule.root, icon: ICONS.calendar,show:false },
      { title: 'app.services', path: PATH_DASHBOARD.service.list, icon: ICONS.service,show:false},
      { title: 'app.skill', path: PATH_DASHBOARD.skill.list, icon: ICONS.skill,show:false },
      // { title: 'app.otherExpenses', path: PATH_DASHBOARD.otherExpenses.list, icon: ICONS.other_finance },
      { title: 'app.finance', path: PATH_DASHBOARD.finance.root, icon: ICONS.finance,show:false },
    ]
  }
];
const sidebarConfigClinic = [
  {
    subheader: 'app.menu',
    items: [
      { title: 'app.overview', path: PATH_DASHBOARD.general.app, icon: ICONS.home},
      { title: 'app.patient', path: PATH_DASHBOARD.patient.list, icon: ICONS.staff },
      { title: 'app.doctors', path: PATH_DASHBOARD.doctors.list, icon: ICONS.doctors},
      { title: 'app.schedule', path: PATH_DASHBOARD.schedule.doctor, icon: ICONS.calendar },
      { title: 'app.services', path: PATH_DASHBOARD.service.root, icon: ICONS.service},
      // { title: 'app.skill', path: PATH_DASHBOARD.skill.list, icon: ICONS.skill },
      { title: 'app.finance', path: PATH_DASHBOARD.finance.root, icon: ICONS.finance },
    ]
  }
];

const sidebarConfigDoctor = [
  {
    subheader: 'app.menu',
    items: [
      { title: 'app.overview', path: PATH_DASHBOARD.general.app, icon: ICONS.home,show:true},
      { title: 'app.services', path: PATH_DASHBOARD.service.root, icon: ICONS.service,show:true},
      { title: 'app.labs', path: PATH_DASHBOARD.labs.root, icon: ICONS.service,show:true},
      { title: 'app.patients', path: PATH_DASHBOARD.patient.list, icon: ICONS.staff ,show:true},
      { title: 'app.schedule', path: PATH_DASHBOARD.schedule.doctor, icon: ICONS.calendar,show:true },
      { title: 'app.finance', path: PATH_DASHBOARD.finance.root, icon: ICONS.finance,show:true },
    ]
  }
];
const sidebarConfigDoctorNot = [
  {
    subheader: 'app.menu',
    items: [
      { title: 'app.overview', path: PATH_DASHBOARD.general.app, icon: ICONS.home,show:true},
      { title: 'app.services', path: PATH_DASHBOARD.service.root, icon: ICONS.service,show:true},
      { title: 'app.labs', path: PATH_DASHBOARD.labs.root, icon: ICONS.service,show:false},
      { title: 'app.patients', path: PATH_DASHBOARD.patient.list, icon: ICONS.staff ,show:false},
      { title: 'app.schedule', path: PATH_DASHBOARD.schedule.root, icon: ICONS.calendar,show:false },
      { title: 'app.finance', path: PATH_DASHBOARD.finance.root, icon: ICONS.finance,show:false },
    ]
  }
];

export { sidebarConfig,sidebarConfigClinic, sidebarConfigAdmin,sidebarConfigDoctor,sidebarConfigDoctorNot,sidebarConfigNot };
