import { Icon } from '@iconify/react';
import { useRef, useState, useCallback, useEffect } from 'react';
import peopleFill from '@iconify/icons-eva/people-fill';
// material
import { alpha } from '@material-ui/core/styles';
import {
    ListItemAvatar,
    Typography,
    ListItemText,
    Avatar,
    ListItemButton,
    DialogContent,
    DialogContentText, DialogTitle, DialogActions, Dialog, Stack, AlertTitle
} from '@material-ui/core';
// hooks
import useIsMountedRef from '../../hooks/useIsMountedRef';
// utils
import axios from '../../utils/axios';
import {calculateAge, fDateTime, fToNow} from '../../utils/formatTime';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import BadgeStatus from '../../components/BadgeStatus';
import { MIconButton } from '../../components/@material-extend';
import useAuth from "../../hooks/useAuth";
import {GetConnects} from "../../utils/fakeRequest";
import {API_URL, IMAGE_URL} from "../../config";
import NotificationSound from "../../assets/sound/sound.mp3"
// import io from "socket.io-client";
import {useSnackbar} from "notistack5";
import useAudio from "../../hooks/useAudio";
import {Alert, Button} from "@mui/material";
import useLocales from "../../hooks/useLocales";

// const socket = io.connect("https://api.agilemedix.com");

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64;
const PADDING_ITEM = 2.5;

export default function ContactsPopover() {
    // const [playing, toggle] = useAudio(NotificationSound);
  const { enqueueSnackbar } = useSnackbar();
    const {translate} = useLocales();
  const audioPlayer = useRef(null);
  const anchorRef = useRef(null);
  const isMountedRef = useIsMountedRef();
  const {user} = useAuth()
  const [open, setOpen] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [dialog, setDialogOpen] = useState(false);
  const [dialogData,setDialogData] = useState(null)


    // const sendMessage = () => {
    //     console.log("Button clicked");
    //     const userId = `${user.id}_${user.rolle}`; // Replace with the actual user ID
    //     const targetUserId = "1_business_lab"; // Replace with the target user's ID
    //     socket.emit("send_message", { userId, targetUserId, message: "Hello from client" });
    // }

    // const sendMessageDoctor = () => {
    //     console.log("Button clicked");
    //     const userId = `${user.id}_${user.rolle}`; // Replace with the actual user ID
    //     const targetUserId = "40_Doctor"; // Replace with the target user's ID
    //     socket.emit("send_message", { userId, targetUserId, message: "Hello from client" });
    // }

    // useEffect(() => {
    //     const userId = `${user.id}_${user.rolle}`;
    //     socket.emit("set_user_id", userId);
    // }, [socket]);


    // useEffect(() => {
    //     socket.on("receive_message", (data) => {
    //         console.log(data)
    //         alert(data.message);
    //     });
    // }, [socket]);

    // useEffect(() => {
    //     const handleReceiveMessage = (data) => {
    //         console.log(data);
    //         setDialogOpen(true)
    //         setDialogData(data?.message)
    //         // toggle()
    //         enqueueSnackbar('send friend', { variant: 'success' });
    //     };
    //
    //     socket.on("receive_message", handleReceiveMessage);
    //
    //
    //     return () => {
    //         // Clean up the event listener when the component unmounts
    //         socket.off("receive_message", handleReceiveMessage);
    //     };
    // }, [socket]);

  const getContacts = useCallback(async () => {
    try {
      const response = await GetConnects(user.id);
      if (isMountedRef.current) {
        setContacts(response?.data?.info);
      }
    } catch (error) {
      console.error(error);
    }
  }, [isMountedRef]);


  useEffect(() => {
    getContacts();
  }, [getContacts]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseDialog = ()=>{
      setDialogOpen(false)
  }

  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Icon icon={peopleFill} width={20} height={20} />
      </MIconButton>

        <Dialog
            open={dialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{textAlign:'left'}} id="alert-dialog-title">
                booking day {fDateTime(dialogData?.date || new Date())}
            </DialogTitle>
            <DialogContent>
                <Stack sx={{flexDirection:'row',gap:2,mt:3}}>
                    <img
                        srcSet={`${IMAGE_URL}/staff/${dialogData?.image}`}
                        src={`${IMAGE_URL}/staff/${dialogData?.image}`}
                        alt={dialogData?.name}
                        loading="lazy"
                        width={100}
                        height={100}
                        style={{width:'100px',height:'100px',objectFit:'cover',borderRadius:'8px'}}
                    />
                    <Stack>
                        <Typography variant={'subtitle1'}>{dialogData?.name}</Typography>
                        <Typography variant={'body2'}>{dialogData?.email}</Typography>
                        <Typography variant={'body2'}>{dialogData?.phone}</Typography>
                        <Typography variant={'body2'}> sery {dialogData?.gender} tariqy {calculateAge(dialogData?.birth_date)} </Typography>
                    </Stack>

                </Stack>
                <Alert sx={{mt:3}} severity="info">
                    <AlertTitle>gangatner</AlertTitle>
                    <strong>{dialogData?.comment}</strong>
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Disagree</Button>
                <Button onClick={handleCloseDialog} autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <Typography variant="h6" sx={{ p: PADDING_ITEM }}>
            {/*<button onClick={()=>sendMessage()}>lab</button>*/}
            {/*<button onClick={()=>sendMessageDoctor()}>doctor</button>*/}
            {translate('app.partner')} <Typography component="span">({contacts.length})</Typography>
        </Typography>

        <Scrollbar sx={{ height: ITEM_HEIGHT * 8 }}>
          {contacts.map((contact) => {
            const { id, name, image } = contact;

            return (
              <ListItemButton disableGutters key={id} sx={{ px: PADDING_ITEM, height: ITEM_HEIGHT }}>
                <ListItemAvatar sx={{ position: 'relative' }}>
                  <Avatar src={`${IMAGE_URL}/staff/${image}`} />
                  {/*<BadgeStatus status={'offline'} sx={{ position: 'absolute', right: 1, bottom: 1 }} />*/}
                </ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{ typography: 'subtitle2', mb: 0.25 }}
                  secondaryTypographyProps={{ typography: 'caption' }}
                  primary={name}
                  // secondary={status === 'offline' && fToNow(lastActivity)}
                />

              </ListItemButton>
            );
          })}
        </Scrollbar>

      </MenuPopover>
    </>
  );
}
