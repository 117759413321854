// material
import { alpha, useTheme, styled } from '@material-ui/core/styles';
import {
    Box,
    Grid,
    Card,
    Container,
    Typography,
    useMediaQuery,
    Avatar,
    Stack,
    Divider,
    CardHeader, CardContent, Link
} from '@material-ui/core';
//
import { varFadeInUp, MotionInView, varFadeInDown } from '../animate';
import useLocales from "../../hooks/useLocales";
import {useEffect, useState} from "react";
import {GetHomeServices, GetServices} from "../../utils/fakeRequest";
import {IMAGE_URL} from "../../config";
import {Block} from "../../pages/components-overview/Block";
import {CarouselCenterMode} from "../carousel";
import {PATH_PAGE} from "../../routes/paths";
import {Link as RouterLink} from "react-router-dom";

// ----------------------------------------------------------------------


const shadowIcon = (color) => `drop-shadow(2px 2px 2px ${alpha(color, 0.48)})`;

const RootStyle = styled('div')(({ theme }) => ({
    // paddingTop: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
        // paddingBottom: theme.spacing(15)
    }
}));

const CardStyle = styled(Stack)(({ theme }) => {
    return {
        maxWidth: 380,
        height:'40px',
        // margin: 'auto',
        display:'flex',
        alignItems:'center',
        flexDirection:'row',
        borderRadius:'8px'
    };
});

const CardIconStyle = styled('img')(({ theme }) => ({
    width: '30px',
    height: '30px',
    // margin: 'auto',
    // objectFit:'cover',
    // marginBottom: theme.spacing(4),
    // filter: shadowIcon(theme.palette.primary.main)
}));

// ----------------------------------------------------------------------

export default function HomeService({service}) {
    const {translate,currentLang} = useLocales();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));



    return (
        <RootStyle sx={{paddingTop: theme.spacing(isMobile ? 2 : 5)}}>
            <Container maxWidth="lg">
                {/*<Box sx={{ mb: { xs: 5, md: 12 } }}>*/}
                        <Typography  variant="h6" sx={{textAlign:{xs: 'center', md: 'left'},color: '#838181'}}>
                            {translate('app.homePageServiceSubTitile')}
                        </Typography>
                {/*</Box>*/}
                <Grid sx={{mb:2,mt:2}} container spacing={isDesktop ? 2 : 2}>
                    {service?.map((card, index) => (
                        <Grid key={card.name} item xs={12} md={3}>
                            {/*<MotionInView variants={varFadeInUp}>*/}
                                <Stack sx={{xs:{minHeight: 'auto'},md: {minHeight: 350, border: 'none', boxShadow: 'none'}}} >
                                    <Typography variant="subtitle1" sx={{xs: {textAlign:'center'},md:{color:'#838181'}}}>{card.name}</Typography>
                                { card.items.map((item,index)=>(
                                    <Link key={index} to={`${PATH_PAGE.doctorsList}?filters=${item.id}`} color="inherit" component={RouterLink}>
                                        <Divider sx={{ borderStyle: 'dashed',p:0,my:1 }} />
                                    <CardStyle>
                                        <Avatar
                                            sx={{
                                                backgroundColor:`#3a98d240`,
                                                width: 40,
                                                height: 40,
                                                padding: '5px',
                                                objectFit: 'none',
                                                mr:'10px'
                                            }}
                                            alt={item.name}
                                            variant={'rounded'}
                                            src={`${IMAGE_URL}/services/${item?.icon}`}/>
                                        <Typography sx={{fontSize:'12px' }}>
                                            {item.name}
                                        </Typography>
                                    </CardStyle>

                                    </Link>

                                ))}
                                </Stack>


                            {/*</MotionInView>*/}
                        </Grid>
                    ))}




                </Grid>
            </Container>
        </RootStyle>
    );
}
