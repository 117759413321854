// ----------------------------------------------------------------------

import axios from './axios';
import { API_URL } from '../config';

export default function fakeRequest(time) {
  return new Promise((res) => setTimeout(res, time));
}

const ChangePasswordProfile = async (data) => {
  try {
    return await axios.post(`${API_URL}/change-password`, data);
  } catch (e) {
    return e;
  }
};

const ProfileUpdate = async (data) => {
  try {
    let bodyFormData = new FormData();
    if(data.imageFile){
      bodyFormData.append('file', data.imageFile);
    }
    if(data?.role !== 'Doctor' || data?.role ==='patient'){
      bodyFormData.append('b_name',data?.b_name)
    }

    bodyFormData.append('first_name',data?.first_name)
    bodyFormData.append('last_name',data?.last_name)
    bodyFormData.append('phone',data?.phone)
    bodyFormData.append('image', data?.photoURL)
    bodyFormData.append('id', data?.id)
    bodyFormData.append('role', data?.role)
    const res = await axios.post(`${API_URL}/profile/update`, bodyFormData);
    return res
  } catch (e) {
    return e;
  }
};

const ProfileUpdateAvatar = async (file) => {
  try {
    let bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const response = await axios.post(`${API_URL}/upload/image`, bodyFormData);
    return response?.data?.file?.id;
  } catch (e) {
    console.log('e', e);
  }
};

const ProfileAvatarSave = async (id) => {
  try {
    return await axios.post(`${API_URL}/images/crop/avatar`, {
      file: id
    });
  } catch (e) {
    console.log('e', e);
  }
};

const CreateExercises = async (data) => {
  try {
    let res = await axios.post(`${API_URL}/v2/exercise`, data);

    if (data.imageFile) {
      let id = await ExercisesUploadImage(data.imageFile);
      await ExercisesImageSave(id, res.data.exercise.id);
    }
    return res;
  } catch (e) {
    console.log('e', e);
  }
};

const UpdateExercises = async (data, id) => {
  try {
    let res = await axios.put(`${API_URL}/v2/exercise/${id}`, data);

    if (data.imageFile) {
      let id = await ExercisesUploadImage(data.imageFile);
      await ExercisesImageSave(id, res.data.exercise.id);
    }
    return res;
  } catch (e) {
    console.log('e', e);
  }
};

const ExercisesUploadImage = async (file) => {
  try {
    let bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const response = await axios.post(`${API_URL}/upload/image`, bodyFormData);
    return response?.data?.file?.id;
  } catch (e) {
    console.log('e', e);
  }
};

const ExercisesImageSave = async (id, eid) => {
  try {
    return await axios.post(`${API_URL}/images/crop/image-exercise`, {
      file: id,
      exercise_id: eid
    });
  } catch (e) {
    console.log('e', e);
  }
};

const CreateWorkout = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/workout`, data);
  } catch (e) {
    console.log('e', e);
  }
};

const UpdateWorkout = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/workout/${id}`, data);
  } catch (e) {
    console.log('e', e);
  }
};

const CreateGroup = async (params) => {
  try {
    const imageFile = params?.groupImage;
    params.groupImage = {};
    const response = await axios.post(`${API_URL}/v2/group`, params);
    const imageId = await uploadImageGroup(`${API_URL}/upload/image`, imageFile);
    await imageGroup(imageId, response.data.group.id);
  } catch (e) {
    console.log('e', e);
  }
};

const UpdateGroup = async (params, id) => {
  try {
    const imageFile = params?.groupImage;
    params.groupImage = {};
    const response = await axios.put(`${API_URL}/v2/group/${id}`, params);
    if (imageFile) {
      const imageId = await uploadImageGroup(`${API_URL}/upload/image`, imageFile);
      await imageGroup(imageId, response.data.group.id);
    }
  } catch (e) {
    console.log('e', e);
  }
};

const uploadImageGroup = async (url, file) => {
  try {
    let bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const response = await axios.post(url, bodyFormData);
    return response?.data?.file?.id;
  } catch (e) {
    console.log('e', e);
  }
};

const imageGroup = async (imageId, groupId) => {
  try {
    const response = await axios.post(`${API_URL}/images/crop/image-group`, {
      file: imageId,
      group_id: groupId
    });
    return response;
  } catch (e) {
    console.log('e', e);
  }
};

const InviteTrainer = async (email, typeId) => {
  try {
    const response = await axios.post(' ${API_URL}/trainers', {
      email: email,
      type_id: typeId
    });
    return response;
  } catch (e) {
    return e;
  }
};

const CreateMeals = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/meal`, data);
  } catch (e) {
    return e;
  }
};
const UpdateMeals = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/meal/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateFootType = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/food-category`, data);
  } catch (e) {
    return e;
  }
};
const GetFoodTypeById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/food-category/${id}`);
  } catch (e) {
    return e;
  }
};
const UpdateFoodType = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/food-category/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateFoot = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/food`, data);
  } catch (e) {
    return e;
  }
};

const UpdateFood = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/food/${id}`, data);
  } catch (e) {
    return e;
  }
};

const GetFoodById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/food/${id}`);
  } catch (e) {
    return e;
  }
};
const GetExerciseType = async () => {
  try {
    return await axios.post(`${API_URL}/getData`, {
      fields: ['liquids', 'foods', 'meals', 'workout']
    });
  } catch (e) {
    return e;
  }
};

const CreateProgramRequest = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/program`, data);
  } catch (e) {
    return e;
  }
};
const UpdateProgramRequest = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/program/${id}`, data);
  } catch (e) {
    return e;
  }
};
const GetProgramById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/program/${id}`);
  } catch (e) {
    return e;
  }
};

const GetMealById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/meal/${id}`);
  } catch (e) {
    return e;
  }
};
const GetWorkoutById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/workout/${id}`);
  } catch (e) {
    return e;
  }
};

const DeleteItemRequest = async (path, id) => {
  try {
    return await axios.post(`${API_URL}/v2/${path}/${id}`, { _method: 'DELETE' });
  } catch (e) {
    return e;
  }
};

const GetListDataRequest = async (path, page) => {
  try {
    return await axios.get(`${API_URL}/v2/${path}?page=${page}`);
  } catch (e) {
    return e;
  }
};

const GetUserList = async (page, role) => {
  try {
    return await axios.post(`${API_URL}/v1/users`, { page: page, role: role });
  } catch (e) {
    return e;
  }
};
const GetUserProfileById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/users/${id}`);
  } catch (e) {
    return e;
  }
};
const GetUserProgramOverview = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/users/${id}/overview`);
  } catch (e) {
    return e;
  }
};
const GetUserProgramActions = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/users/${id}/actions`);
  } catch (e) {
    return e;
  }
};

const GetUserProgramList = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/users/${id}/programs`);
  } catch (e) {
    return e;
  }
};
const GetUserProgramActionsHistory = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/users/${id}/actions?history=true`);
  } catch (e) {
    return e;
  }
};

const GetOverview = async () => {
  try {
    return await axios.get(`${API_URL}/v2/overview`);
  } catch (e) {
    return e;
  }
};

const GetDataRequest = async (type) => {
  try {
    return await axios.post(`${API_URL}/v2/getData`, {
      fields: type
    });
  } catch (e) {
    return e;
  }
};
const GetExerciseItem = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/exercise/${id}`);
  } catch (e) {
    return e;
  }
};
// --------------------------------------123-----------------------------------------

const CreateLiquidCategory = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/liquid-category`, data);
  } catch (e) {
    return e;
  }
};

const UpdateLiquidCategory = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/liquid-category/${id}`, data);
  } catch (e) {
    return e;
  }
};

const GetLiquidCategoryById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/liquid-category/${id}`);
  } catch (e) {
    return e;
  }
};

const GetLiquidCategories = async (data) => {
  try {
    return await axios.get(`${API_URL}/v2/liquid-category`, data);
  } catch (e) {
    return e;
  }
};
const GetTrainerTypeId = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/trainer-type/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateTrainerType = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/trainer-type/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateTrainerType = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/trainer-type`, data);
  } catch (e) {
    return e;
  }
};

const GetWeightById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/weight/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateWeight = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/weight/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateWeight = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/weight`, data);
  } catch (e) {
    return e;
  }
};
const GetTargetById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/target/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateTarget = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/target/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateTarget = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/target`, data);
  } catch (e) {
    return e;
  }
};
const GetUserLevelById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/user-level/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateUserLevel = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/user-level/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateUserLevel = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/user-level`, data);
  } catch (e) {
    return e;
  }
};

const GetActivityLevelById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/activity-level/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateActivityLevel = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/activity-level/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateActivityLevel = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/activity-level`, data);
  } catch (e) {
    return e;
  }
};

const GetAgeRangeById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/age/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateAgeRange = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/age/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateAgeRange = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/age`, data);
  } catch (e) {
    return e;
  }
};
const GetBmiRangeById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/bmi/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateBmiRange = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/bmi/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateBmiRange = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/bmi`, data);
  } catch (e) {
    return e;
  }
};
const GetExerciseCategoryById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/exercise-type/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateExerciseCategory = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/exercise-type/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateExerciseCategory = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/exercise-type`, data);
  } catch (e) {
    return e;
  }
};
const GetMuscleCategoryById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/muscle-type/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateMuscleCategory = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/muscle-type/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateMuscleCategory = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/muscle-type`, data);
  } catch (e) {
    return e;
  }
};
const GetLiquidById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/liquid/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateLiquid = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/liquid/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateLiquid = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/liquid`, data);
  } catch (e) {
    return e;
  }
};
const GetHeightById = async (id) => {
  try {
    return await axios.get(`${API_URL}/v2/height/${id}`);
  } catch (e) {
    return e;
  }
};

const UpdateHeight = async (data, id) => {
  try {
    return await axios.put(`${API_URL}/v2/height/${id}`, data);
  } catch (e) {
    return e;
  }
};

const CreateHeight = async (data) => {
  try {
    return await axios.post(`${API_URL}/v2/height`, data);
  } catch (e) {
    return e;
  }
};
const GetUserPartnerList = async (page) => {
  try {
    return await axios.post(`${API_URL}/v2/users`, { page: page, role: 'partner' });
  } catch (e) {
    return e;
  }
};


// ------------------
const GetUser = async (bid, role) => {
  try {
    return await axios.get(`${API_URL}/auth/get-me/getStaff/${bid}/${role}`);
  } catch (e) {
    return e;
  }
};
const GetMyDoctor = async (id) => {
  try {
    return await axios.get(`${API_URL}/labs/doctor/${id}`);
  } catch (e) {
    return e;
  }
};

const GetService = async (bid) => {
  try {
    return await axios.get(`${API_URL}/service/${bid}`);
  } catch (e) {
    return e;
  }
};
const GetServiceV2 = async (lab_id,lang) => {
  try {
    return await axios.get(`${API_URL}/service/${lab_id}/${lang}`);
  } catch (e) {
    return e;
  }
};
const AddService = async (data) => {
  try {
    return await axios.post(`${API_URL}/service`,data);
  } catch (e) {
    return e;
  }
};
const UpdateService = async (data) => {
  try {
    return await axios.post(`${API_URL}/service/update`,data);
  } catch (e) {
    return e;
  }
};
const DeleteService = async (data) => {
  try {
    return await axios.post(`${API_URL}/service/delete`,data);
  } catch (e) {
    return e;
  }
};
const GetByIdService = async (id) => {
  try {
    return await axios.get(`${API_URL}/service/detail/${id}`);
  } catch (e) {
    return e;
  }
};


const GetServices = async (lang,lab) => {
  try {
    return await axios.get(`${API_URL}/services/${lang}/${lab}`);
  } catch (e) {
    return e;
  }
};
const GetDoctorsPatinet = async () => {
  try {
    return await axios.get(`${API_URL}/services/doctors/list`);
  } catch (e) {
    return e;
  }
};

const GetServicePartner = async (bid,did) => {
  try {
    return await axios.get(`${API_URL}/service/partner/${bid}/${did}`);
  } catch (e) {
    return e;
  }
};
const GetServiceStaffSkill = async (bid) => {
  try {
    return await axios.get(`${API_URL}/service/skills/${bid}/hy`);
  } catch (e) {
    return e;
  }
};

const GetAppointments = async (bid) => {
  try {
    return await axios.get(`${API_URL}/appointment/${bid}`);
  } catch (e) {
    return e;
  }
};
const InsertAppointment = async (data) => {
  try {
    return await axios.post(`${API_URL}/appointment`, { ...data});
  } catch (e) {
    return e;
  }
};
const UpdateAppointmentInfo = async (data) => {
  try {
    return await axios.post(`${API_URL}/appointment/update/info`, { ...data});
  } catch (e) {
    return e;
  }
};
const UpdateAppointmentTooth = async (data) => {
  try {
    return await axios.post(`${API_URL}/appointment/update/tooth`, { ...data});
  } catch (e) {
    return e;
  }
};
const UpdateAppointmentWork = async (data) => {
  try {
    return await axios.post(`${API_URL}/appointment/update/employ/work`, { ...data});
  } catch (e) {
    return e;
  }
};
const AddAppointmentWork = async (data) => {
  try {
    return await axios.post(`${API_URL}/appointment/add/employ/work`, { ...data});
  } catch (e) {
    return e;
  }
};
const DeleteAppointmentWork = async (data) => {
  try {
    return await axios.post(`${API_URL}/appointment/delete/employ/work`, { ...data});
  } catch (e) {
    return e;
  }
};
const StepAppointmentAdd = async (data) => {
  try {
    return await axios.post(`${API_URL}/appointment/step/add`, { ...data});
  } catch (e) {
    return e;
  }
};

const StepAppointmentUpdate = async (data) => {
  try {
    return await axios.post(`${API_URL}/appointment/step/update`, { ...data});
  } catch (e) {
    return e;
  }
};
const StepAppointmentDelete = async (data) => {
  try {
    return await axios.post(`${API_URL}/appointment/step/delete`, { ...data});
  } catch (e) {
    return e;
  }
};

const GetEmploymentSkills = async (bid) => {
  try {
    return await axios.get(`${API_URL}/employment-skills/${bid}`);
  } catch (e) {
    return e;
  }
};

const AddStaff = async (data) => {
  try {
    return await axios.post(`${API_URL}/auth/get-me/addstaff`, data);
  } catch (e) {
    return e;
  }
};
const UpdateStaff = async (data) => {
  try {
    return await axios.post(`${API_URL}/auth/get-me/addstaff/update`, data);
  } catch (e) {
    return e;
  }
};

const AddSkill = async (data) => {
  try {
    return await axios.post(`${API_URL}/employment-skills`, data);
  } catch (e) {
    return e;
  }
};
const GetByIdSkill = async (id) => {
  try {
    return await axios.get(`${API_URL}/employment-skills/detail/${id}`);
  } catch (e) {
    return e;
  }
};
const UpdateSkill = async (data) => {
  try {
    return await axios.post(`${API_URL}/employment-skills/update`,data);
  } catch (e) {
    return e;
  }
};
const DeleteSkill = async (data) => {
  try {
    return await axios.post(`${API_URL}/employment-skills/delete`,data);
  } catch (e) {
    return e;
  }
};
const FreeSubscription = async (data) => {
  try {
    return await axios.post(`${API_URL}/paymant/create-subscription/free`,data);
  } catch (e) {
    return e;
  }
};

const GetInfoSubscription = async (session_id) => {
  try {
    return await axios.get(`${API_URL}/paymant/get-session/${session_id}`);
  } catch (e) {
    return e;
  }
};
const CreateSubscription = async (data) => {
  try {
    return await axios.post(`${API_URL}/paymant/create-checkout-session`,data);
  } catch (e) {
    return e;
  }
};
const SignUpInPay = async (data) => {
  try {
    return await axios.post(`${API_URL}/auth/sign-up/create-checkout-session`,data);
  } catch (e) {
    return e;
  }
};

const UpdateSubscription = async (data) => {
  try {
    return await axios.post(`${API_URL}/paymant/subscription/update`,data);
  } catch (e) {
    return e;
  }
};
const AddSubscription = async (data) => {
  try {
    return await axios.post(`${API_URL}/paymant/subscription/add`,data);
  } catch (e) {
    return e;
  }
};
const DeleteStaff = async (data) => {
  try {
    return await axios.post(`${API_URL}/auth/get-me/addstaff/delete`, data);
  } catch (e) {
    return e;
  }
};
const RequestPlan = async (data) => {
  try {
    return await axios.post(`${API_URL}/paymant/request-plan`, data);
  } catch (e) {
    return e;
  }
};

const GetStaffDetalis = async (uid) => {
  try {
    return await axios.get(`${API_URL}/auth/get-me/getStaff/${uid}`);
  } catch (e) {
    return e;
  }
};
const GetDoctorDetalis = async (uid,labid) => {
  try {
    return await axios.get(`${API_URL}/auth/get-me/getDoctor/${uid}/${labid}`);
  } catch (e) {
    return e;
  }
};
const GetOverViwe = async (uid,role) => {
  try {
    return await axios.get(`${API_URL}/auth/get-me/overviwe/${uid}/${role}`);
  } catch (e) {
    return e;
  }
};

const GetSubscription = async (uid) => {
  try {
    return await axios.get(`${API_URL}/paymant/subscription/${uid}`);
  } catch (e) {
    return e;
  }
};


const AddOtherExpenses = async (data) => {
  try {
    return await axios.post(`${API_URL}/other-expenses`, data);
  } catch (e) {
    return e;
  }
};
const UpdateOtherExpenses = async (data) => {
  try {
    return await axios.post(`${API_URL}/other-expenses/update`, data);
  } catch (e) {
    return e;
  }
};
const GetOtherExpenses = async (uid) => {
  try {
    return await axios.get(`${API_URL}/other-expenses/${uid}`);
  } catch (e) {
    return e;
  }
};
const GetOtherExpensesDate = async (uid,date) => {
  try {
    return await axios.get(`${API_URL}/other-expenses/${uid}/${date}`);
  } catch (e) {
    return e;
  }
};
const GetFinanceStaff = async (uid) => {
  try {
    return await axios.get(`${API_URL}/other-expenses/finance/${uid}`);
  } catch (e) {
    return e;
  }
};


const GetPartnerFinanceList = async (uid) => {
  try {
    return await axios.get(`${API_URL}/other-expenses/business/list/${uid}`);
  } catch (e) {
    return e;
  }
};
const GetFinanceStaffById = async (uid,sid,start,finish) => {
  try {
    return await axios.get(`${API_URL}/other-expenses/finance/staff/${uid}/${sid}/${start}/${finish}`);
  } catch (e) {
    return e;
  }
};
const GetFinancePartnerById = async (uid,pid,start,finish) => {
  try {
    return await axios.get(`${API_URL}/other-expenses/business/list/${uid}/${pid}/${start}/${finish}`);
  } catch (e) {
    return e;
  }
};
const GetFinancePartnerByIdCalculate = async (id) => {
  try {
    return await axios.get(`${API_URL}/other-expenses/business/partner/finance/sum/${id}`);
  } catch (e) {
    return e;
  }
};

const GetFinanceOverview = async (uid) => {
  try {
    return await axios.get(`${API_URL}/other-expenses/finance/all/sum`);
  } catch (e) {
    return e;
  }
};
const GetServiceChart = async (data) => {
  try {
    return await axios.post(`${API_URL}/other-expenses/finance/chart/list`,data);
  } catch (e) {
    return e;
  }
};
const AddTransactions = async (data) => {
  try {
    return await axios.post(`${API_URL}/transactions/add`,data);
  } catch (e) {
    return e;
  }
};

const GetTransactions = async (id) => {
  try {
    return await axios.get(`${API_URL}/transactions/${id}`);
  } catch (e) {
    return e;
  }
};
const UpdateWorkingTime = async (data) => {
  try {
    return await axios.post(`${API_URL}/profile/working-time/update`,data);
  } catch (e) {
    return e;
  }
};
const GetWorkingTime = async (id,role) => {
  try {
    return await axios.get(`${API_URL}/profile/working-time/${id}/${role}`);
  } catch (e) {
    return e;
  }
};
const GetWorkingDoctorTime = async (cid,did) => {
  try {
    return await axios.get(`${API_URL}/profile/working-time/${cid}/${did}`);
  } catch (e) {
    return e;
  }
};
const AddWorkingDoctorTime = async (data) => {
  try {
    return await axios.post(`${API_URL}/profile/working-time/doctors/add`,data);
  } catch (e) {
    return e;
  }
};
const UpdateWorkingDoctorTime = async (data) => {
  try {
    return await axios.post(`${API_URL}/profile/working-time/doctors/update`,data);
  } catch (e) {
    return e;
  }
};
const GetAddresses = async (id,role) => {
  try {
    return await axios.get(`${API_URL}/profile/addresses/${id}/${role}`);
  } catch (e) {
    return e;
  }
};
const AddAddresses = async (data) => {
  try {
    return await axios.post(`${API_URL}/profile/addresses/add`,data);
  } catch (e) {
    return e;
  }
};
const UpdateAddresses = async (data) => {
  try {
    return await axios.post(`${API_URL}/profile/addresses/update`,data);
  } catch (e) {
    return e;
  }
};
const DeleteAddresses = async (data) => {
  try {
    return await axios.post(`${API_URL}/profile/addresses/delete`,data);
  } catch (e) {
    return e;
  }
};

const GetFinance = async (id) => {
  try {
    return await axios.get(`${API_URL}/finance/${id}`);
  } catch (e) {
    return e;
  }
};
const GetFinanceDoctorByID = async (id) => {
  try {
    // role=doctor
    return await axios.get(`${API_URL}/finance/overviwe/doctor/${id}`);
  } catch (e) {
    return e;
  }
};

const GetMyLabs = async (id) => {
  try {
    // role=doctor
    return await axios.get(`${API_URL}/finance/overviwe/doctor/labs/${id}`);
  } catch (e) {
    return e;
  }
};
const GetFinanceDoctor = async (id,did) => {
  try {
    return await axios.get(`${API_URL}/finance/${id}/${did}`);
  } catch (e) {
    return e;
  }
};
const GetFinanceStaffV2 = async (id,lid) => {
  try {
    return await axios.get(`${API_URL}/finance/staff/${id}/${lid}`);
  } catch (e) {
    return e;
  }
};
const GetInvoice = async (data) => {
  try {
    return await axios.post(`${API_URL}/invoice`,data);
  } catch (e) {
    return e;
  }
};
const UpdateInvoceAmount = async (data) => {
  try {
    return await axios.post(`${API_URL}/invoice/update`,data);
  } catch (e) {
    return e;
  }
};

const AddInvoice = async (data) => {
  try {
    return await axios.post(`${API_URL}/invoice/add`,data);
  } catch (e) {
    return e;
  }
};
const GetInvoiceList = async (id) => {
  try {
    return await axios.get(`${API_URL}/invoice/list/${id}`);
  } catch (e) {
    return e;
  }
};
const GetInvoiceId = async (inv) => {
  try {
    return await axios.get(`${API_URL}/invoice/${inv}`);
  } catch (e) {
    return e;
  }
};


const GetPatientRequest = async (id,role) => {
  try {
    return await axios.get(`${API_URL}/patient/${id}/${role}`);
  } catch (e) {
    return e;
  }
};
const GetPatientDetails = async (did,pid,lang) => {
  try {
    return await axios.get(`${API_URL}/patient/details/${did}/${pid}/${lang}`);
  } catch (e) {
    return e;
  }
};
const AddPatientRequest = async (data) => {
  try {
    return await axios.post(`${API_URL}/patient/add`,data);
  } catch (e) {
    return e;
  }
};

const AddClinicService = async (data) => {
  try {
    return await axios.post(`${API_URL}/clinic/service/add`,data);
  } catch (e) {
    return e;
  }
};
const AddDoctorService = async (data) => {
  try {
    return await axios.post(`${API_URL}/clinic/service/business/doctor/add`,data);
  } catch (e) {
    return e;
  }
};
const GetPartnerPriceList = async (did) => {
  try {
    return await axios.get(`${API_URL}/labs/partner/price-list/${did}`);
  } catch (e) {
    return e;
  }
};
const DeleteClinicService = async (data) => {
  try {
    return await axios.post(`${API_URL}/clinic/service/delete`,data);
  } catch (e) {
    return e;
  }
};
const UpdateClinicService = async (data) => {
  try {
    return await axios.post(`${API_URL}/clinic/service/update`,data);
  } catch (e) {
    return e;
  }
};
const GetClinicService = async (id,role,address,lang) => {
  try {
    return await axios.get(`${API_URL}/clinic/service/${id}/${role}/${address}/${lang}`);
  } catch (e) {
    return e;
  }
};
const GetLabService = async (id,address,lang) => {
  try {
    return await axios.get(`${API_URL}/clinic/service/${id}/${address}/${lang}`);
  } catch (e) {
    return e;
  }
};
const GetDoctorClinicService = async (did,cid) => {
  try {
    return await axios.get(`${API_URL}/clinic/service/doctor/business/${did}/${cid}`);
  } catch (e) {
    return e;
  }
};
const GetDoctorClinicServicePrice = async (did,cid,lang) => {
  try {
    return await axios.get(`${API_URL}/clinic/service/doctor/business/price/${did}/${cid}/${lang}`);
  } catch (e) {
    return e;
  }
};
const DeleteDoctorClinicService = async (data) => {
  try {
    return await axios.post(`${API_URL}/clinic/service/doctor/delete`,data);
  } catch (e) {
    return e;
  }
};
const UpdateDoctorClinicService = async (data) => {
  try {
    return await axios.post(`${API_URL}/clinic/service/doctor/update`,data);
  } catch (e) {
    return e;
  }
};

const GetHomeServices = async (lang) => {
  try {
    return await axios.get(`${API_URL}/clinic/service/top/${lang}`);
  } catch (e) {
    return e;
  }
};

const GetClinic = async (id,lang) => {
  try {
    return await axios.get(`${API_URL}/clinic/${id}/${lang}`);
  } catch (e) {
    return e;
  }
};
const AddDoctorAppointment = async (data) => {
  try {
    return await axios.post(`${API_URL}/doctor/work`,data);
  } catch (e) {
    return e;
  }
};
const GetDoctorAppointmentDetail = async (data) => {
  try {
    return await axios.post(`${API_URL}/doctor/work/schedule/appointment/detail`,data);
  } catch (e) {
    return e;
  }
};
const UpdateDoctorAppointment = async (data) => {
  try {
    return await axios.post(`${API_URL}/doctor/work/update`,data);
  } catch (e) {
    return e;
  }
};
const InsertDoctorAppointment = async (data) => {
  try {
    return await axios.post(`${API_URL}/doctor/work/insert`,data);
  } catch (e) {
    return e;
  }
};
const UpdateDoctorAppointmentStatus = async (data) => {
  try {
    return await axios.post(`${API_URL}/doctor/work/update/status`,data);
  } catch (e) {
    return e;
  }
};
const DoctorWorkDelete = async (data) => {
  try {
    return await axios.post(`${API_URL}/doctor/work/delete`,data);
  } catch (e) {
    return e;
  }
};
const PayDoctorAppointment = async (data) => {
  try {
    return await axios.post(`${API_URL}/doctor/work/pay`,data);
  } catch (e) {
    return e;
  }
};

const AddConnects = async (data) => {
  try {
    return await axios.post(`${API_URL}/connects`,data);
  } catch (e) {
    return e;
  }
};
const GetConnects = async (id) => {
  try {
    return await axios.get(`${API_URL}/connects/${id}`);
  } catch (e) {
    return e;
  }
};
const UpdateConnects = async (data) => {
  try {
    return await axios.post(`${API_URL}/connects/update`,data);
  } catch (e) {
    return e;
  }
};
const DetDoctorSchedule = async (id,lang) => {
  try {
    return await axios.get(`${API_URL}/doctor/work/schedule/${id}/${lang}`);
  } catch (e) {
    return e;
  }
};

const GetLabsList = async () => {
  try {
    return await axios.get(`${API_URL}/labs`);
  } catch (e) {
    return e;
  }
};


const CheckPromoCode = async (data) => {
  try {
    return await axios.post(`${API_URL}/auth/sign-up/check-promo-code`,data);
  } catch (e) {
    return e;
  }
};

const UpdateStatusWork = async (data) => {
  try {
    return await axios.post(`${API_URL}/appointment/update/admin/status`,data);
  } catch (e) {
    return e;
  }
};

const GetConsulting = async (id,role) => {
  try {
    return await axios.get(`${API_URL}/consulting/${id}/${role}`);
  } catch (e) {
    return e;
  }
};

const GetDoctorById = async (id,lang,date) => {
  const inputDate = new Date(date);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(inputDate.getDate()).padStart(2, '0');
  const hours = String(inputDate.getHours()).padStart(2, '0');
  const minutes = String(inputDate.getMinutes()).padStart(2, '0');
  const seconds = String(inputDate.getSeconds()).padStart(2, '0');
  let data ={
    id:id,
    lang:lang,
    checkDay:`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
  }
  try {
    return await axios.post(`${API_URL}/clinic/doctor`,data);
  } catch (e) {
    return e;
  }
};

const GetDoctorTimes = async (id,address,days) => {
  const inputDate = new Date(days);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(inputDate.getDate()).padStart(2, '0');
  const hours = String(inputDate.getHours()).padStart(2, '0');
  const minutes = String(inputDate.getMinutes()).padStart(2, '0');
  const seconds = String(inputDate.getSeconds()).padStart(2, '0');


  let data ={
    id:id,
    address_id:address,
    checkDay:`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
  }
  try {
    return await axios.post(`${API_URL}/doctor/work/schedule/time`,data);
  } catch (e) {
    return e;
  }
};

const CreateWallet = async (data) => {
  try {
    return await axios.post(`${API_URL}/wallet/create`,data);
  } catch (e) {
    return e;
  }
};
const UpdateWallet = async (data) => {
  try {
    return await axios.post(`${API_URL}/wallet/update`,data);
  } catch (e) {
    return e;
  }
};


export {
  ChangePasswordProfile,
  CreateProgramRequest,
  UpdateProgramRequest,
  GetExerciseType,
  CreateExercises,
  UpdateExercises,
  GetFoodTypeById,
  CreateFootType,
  GetProgramById,
  UpdateFoodType,
  CreateWorkout,
  ProfileUpdate,
  UpdateWorkout,
  InviteTrainer,
  GetFoodById,
  fakeRequest,
  CreateGroup,
  UpdateGroup,
  CreateMeals,
  UpdateMeals,
  GetMealById,
  CreateFoot,
  UpdateFood,
  GetWorkoutById,
  DeleteItemRequest,
  GetListDataRequest,
  GetUserList,
  GetUserProfileById,
  GetUserProgramOverview,
  GetUserProgramActions,
  GetUserProgramList,
  GetUserProgramActionsHistory,
  GetOverview,
  GetDataRequest,
  GetExerciseItem,
  //
  CreateLiquidCategory,
  UpdateLiquidCategory,
  GetLiquidCategoryById,
  GetLiquidCategories,
  GetTrainerTypeId,
  UpdateTrainerType,
  CreateTrainerType,
  GetWeightById,
  UpdateWeight,
  CreateWeight,
  GetTargetById,
  UpdateTarget,
  CreateTarget,
  GetUserLevelById,
  UpdateUserLevel,
  CreateUserLevel,
  GetActivityLevelById,
  UpdateActivityLevel,
  CreateActivityLevel,
  GetAgeRangeById,
  UpdateAgeRange,
  CreateAgeRange,
  GetBmiRangeById,
  UpdateBmiRange,
  CreateBmiRange,
  GetExerciseCategoryById,
  UpdateExerciseCategory,
  CreateExerciseCategory,
  GetMuscleCategoryById,
  UpdateMuscleCategory,
  CreateMuscleCategory,
  GetLiquidById,
  UpdateLiquid,
  CreateLiquid,
  GetHeightById,
  UpdateHeight,
  CreateHeight,
  GetUserPartnerList,

  GetUser,
  GetService,
  GetAppointments,
  GetServicePartner,
  InsertAppointment,
  GetServiceStaffSkill,
  GetEmploymentSkills,
  AddStaff,
  DeleteStaff,
  GetStaffDetalis,
  UpdateStaff,
  GetDoctorDetalis,
  GetOverViwe,
  AddService,
  UpdateService,
  GetByIdService,
  DeleteService,
  AddSkill,
  GetByIdSkill,
  UpdateSkill,
  DeleteSkill,
  FreeSubscription,
  GetSubscription,
  RequestPlan,
  GetOtherExpenses,
  AddOtherExpenses,
  UpdateOtherExpenses,
  GetOtherExpensesDate,
  GetFinanceStaff,
  GetServiceChart,
  GetFinanceOverview,
  GetFinanceStaffById,
  GetPartnerFinanceList,
  GetFinancePartnerById,
  GetFinancePartnerByIdCalculate,
  UpdateAppointmentInfo,
  UpdateAppointmentTooth,
  UpdateAppointmentWork,
  AddAppointmentWork,
  DeleteAppointmentWork,
  StepAppointmentAdd,
  StepAppointmentUpdate,
  StepAppointmentDelete,
  AddTransactions,
  GetTransactions,
  UpdateWorkingTime,
  GetWorkingTime,
  GetAddresses,
  AddAddresses,
  UpdateAddresses,
  DeleteAddresses,
  GetFinance,
  GetFinanceDoctor,
  GetFinanceStaffV2,
  GetInvoice,
  AddInvoice,
  GetInvoiceList,
  GetInvoiceId,
  UpdateInvoceAmount,

  GetPatientRequest,
  AddPatientRequest,
  GetServices,
  AddClinicService,
  DeleteClinicService,
  UpdateClinicService,
  GetClinicService,
  GetWorkingDoctorTime,
  AddWorkingDoctorTime,
  UpdateWorkingDoctorTime,
  AddDoctorService,
  GetDoctorClinicService,
  DeleteDoctorClinicService,
  GetDoctorClinicServicePrice,
  UpdateDoctorClinicService,

    // front
  GetClinic,
  GetHomeServices,
  GetInfoSubscription,
  CreateSubscription,
  UpdateSubscription,
  AddSubscription,
  AddDoctorAppointment,
  DetDoctorSchedule,
  UpdateDoctorAppointment,
  UpdateDoctorAppointmentStatus,
  PayDoctorAppointment,
  GetLabsList,
  AddConnects,
  UpdateConnects,
  GetConnects,
  GetMyDoctor,
  GetPatientDetails,
  GetFinanceDoctorByID,
  DoctorWorkDelete,
  GetMyLabs,
  SignUpInPay,
  CheckPromoCode,
  GetDoctorsPatinet,
  GetDoctorById,
  GetDoctorTimes,
  GetConsulting,
  UpdateStatusWork,
  CreateWallet,
  UpdateWallet,
  GetDoctorAppointmentDetail,
  InsertDoctorAppointment,
  GetLabService,
  GetServiceV2,
  GetPartnerPriceList
};
