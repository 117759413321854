import { styled } from '@material-ui/core/styles';
import Page from '../components/Page';
import useLocales from "../hooks/useLocales";
import MainFooter from "../layouts/main/MainFooter";
import MainNavbar from "../layouts/main/MainNavbar";
import HeroHome from "../components/home/HeroHome";
import HomeService from "../components/home/HomeService";
import {useEffect, useState} from "react";
import {GetServices} from "../utils/fakeRequest";
import LandingDarkMode from "../components/_external-pages/landing/LandingDarkMode";


const RootStyle = styled(Page)({
    height: '100%',
    margin:0
});

const ContentStyle = styled('div')(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    marginTop:100
}));

export default function LandingPage() {
    const {translate,currentLang} = useLocales();
    const [service,setService] = useState([])
    const [searching,setSearching] = useState([])

    useEffect(()=>{
        GetServices(currentLang.value).then((res)=>{
            setService(res.data.info)
            setSearching(res.data.searching)
        })
    },[currentLang?.value])

    return (
        <RootStyle  title={`AgileMedix - ${translate('app.slogn')}`} id="move_top">
            <MainNavbar notCover={true} business={true}/>
            <ContentStyle>
                <HeroHome searching={searching}/>
                <HomeService service={service} />
            </ContentStyle>
            <MainFooter/>
            <LandingDarkMode/>
        </RootStyle>
    );
}
