import { useTranslation } from 'react-i18next';
// material
import { enUS, deDE, frFR, hyAM ,esES} from '@material-ui/core/locale';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import {replace} from "lodash";
import {PATH_PAGE} from "../routes/paths";

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/static/icons/ic_flag_en.svg'
  },
  {
    label: 'German',
    value: 'de',
    systemValue: deDE,

    icon: '/static/icons/ic_flag_de.svg'
  },
  {
    label: 'French',
    value: 'fr',
    systemValue: frFR,
    icon: '/static/icons/ic_flag_fr.svg'
  },
  {
    label: 'Armenia',
    value: 'hy',
    systemValue: hyAM,
    icon: '/static/icons/ic_flag_hy.svg'
  },
  {
    label: 'Spanish',
    value: 'es',
    systemValue: esES,
    icon: '/static/icons/ic_flag_es.svg'
  }
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const { pathname } = useLocation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];
  const navigate = useNavigate();
  const navigationExtras = {
    // Set other options as needed
    skipLocationChange: true, // This will prevent adding the URL to the browser history
  };
  const handleChangeLanguage = (newlang) => {
   if(newlang){
     i18n.changeLanguage(newlang);
     // let url = pathname.split('/')
     // url[1] = newlang
     // // navigate(`${url.join('/')}`)
     //     // <Navigate to={`/${currentLang.value}`} replace />
     // window.location.replace(`${url.join('/')}`)
   }

  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS
  };
}
