import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import {Box, Button, Typography, Container, Stack} from '@material-ui/core';
// components
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';
import { PageNotFoundIllustration } from '../assets';
import OrderCompleteIllustration from "../assets/illustration_order_complete";
import PlanFreeIcon from "../assets/icon_plan_free";
import {PATH_DASHBOARD} from "../routes/paths";
import {useEffect, useState} from "react";
import {AddSubscription, GetInfoSubscription, UpdateSubscription} from "../utils/fakeRequest";
import useAuth from "../hooks/useAuth";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function SubscriptionSuccess() {
    const {user} = useAuth()

    useEffect(()=>{
        const searchParams = new URLSearchParams(location.search);
        const sessionId = searchParams.get('session_id');
        console.log('user?.subscription_date',user)
        if(sessionId){
            validSubscription(sessionId)
        }
    },[])

    const validSubscription  = async (id) =>{
        const {data} =  await GetInfoSubscription(id)

        if(data.status === 'complete'){
            if(!user?.subscription_date){
                await AddSubscription({id:data.uid,date:data.date})
            }else{
                await UpdateSubscription({id:data.uid,date:data.date})
            }

        }
    }

    const goToDashbord =  () =>{
        window.location.href = '/dashboard'
    }

    return (
        <RootStyle title="succsess  | AgileMedix">
            <Container>
                <MotionContainer initial="initial" open>
                    <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
                        <motion.div variants={varBounceIn}>
                            <Typography variant="h3" paragraph>
                                Շնորհավորում ենք
                            </Typography>
                        </motion.div>
                        <Typography sx={{ color: 'text.secondary' }}>
                            Բաժանորդագրությունը հաջողությամ ավարտվեց շնորհակալ ենք մեզ վստահելու համար
                        </Typography>

                        <motion.div variants={varBounceIn}>
                            <Stack sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Box component="img" src="/static/illustrations/pro.png" sx={{height: 260, my: { xs: 5, sm: 10 } }} />
                            </Stack>

                        </motion.div>

                        <Button onClick={()=>goToDashbord()}  size="large" variant="contained">
                            Շատ լավ
                        </Button>
                    </Box>
                </MotionContainer>
            </Container>
        </RootStyle>
    );
}
