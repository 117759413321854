function path(root, sublink) {
   return `${root}${sublink}`;
}

const langPath = (sublink) => {
  return `${sublink}`;
};

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_DASHBOARD_ADMIN = '/admin';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  signup: path(ROOTS_AUTH, '/signup'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  verification: path(ROOTS_AUTH, '/verification'),
  home: '/'
};



export var PATH_PAGE = {
  comingSoon: langPath('/coming-soon'),
  profile: langPath('/profile'),
  consulting: langPath('/consulting'),
  maintenance: langPath('/maintenance'),
  pricing: langPath('/pricing'),
  payment: langPath('/payment'),
  about: langPath('/about-us'),
  contact: langPath('/contact-us'),
  faqs: langPath('/faqs'),
  page404: langPath('/404'),
  page500: langPath('/500'),
  components: langPath('/components'),
  privacyPolicy: langPath('/privacy-policy'),
  termsAndCondition: langPath('/terms-and-condition'),
  clinics: langPath('/clinics/:id'),
  clinicsList: langPath('/clinics'),
  doctorsList: langPath('/doctors'),
  forPartner: langPath('/for-partner'),
  doctorById: langPath('/doctors/:id'),
};



export const PATH_ADMIN_DASHBOARD = {
  root: ROOTS_DASHBOARD_ADMIN,
  general: {
    app: path(ROOTS_DASHBOARD_ADMIN, '/app'),
    profile: path(ROOTS_DASHBOARD_ADMIN, '/profile')
  },
  partner: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/partner'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/partner/list'),
    show: path(ROOTS_DASHBOARD_ADMIN, '/partner/show/:id')
  },
  foodType: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/food-type'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/food-type/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/food-type/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/food-type/edit/:id')
  },
  food: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/food'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/food/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/food/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/food/edit/:id')
  },
  trainerType: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/trainer-type'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/trainer-type/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/trainer-type/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/trainer-type/edit/:id')
  },
  liquidCategory: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/liquid-category'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/liquid-category/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/liquid-category/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/liquid-category/edit/:id')
  },
  weight: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/weight'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/weight/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/weight/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/weight/edit/:id')
  },
  target: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/target'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/target/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/target/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/target/edit/:id')
  },
  userLevel: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/user-level'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/user-level/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/user-level/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/user-level/edit/:id')
  },
  activityLevel: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/activity-level'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/activity-level/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/activity-level/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/activity-level/edit/:id')
  },
  ageRange: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/age-range'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/age-range/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/age-range/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/age-range/edit/:id')
  },
  bmiRange: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/bmi-range'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/bmi-range/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/bmi-range/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/bmi-range/edit/:id')
  },
  exerciseCategory: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/exercise-category'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/exercise-category/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/exercise-category/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/exercise-category/edit/:id')
  },
  liquid: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/liquid'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/liquid/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/liquid/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/liquid/edit/:id')
  },
  muscleCategory: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/muscle-category'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/muscle-category/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/muscle-category/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/muscle-category/edit/:id')
  },
  height: {
    root: path(ROOTS_DASHBOARD_ADMIN, '/height'),
    list: path(ROOTS_DASHBOARD_ADMIN, '/height/list'),
    add: path(ROOTS_DASHBOARD_ADMIN, '/height/add'),
    edit: path(ROOTS_DASHBOARD_ADMIN, '/height/edit/:id')
  }
};

export const PATH_DASHBOARD = {
  root: langPath(ROOTS_DASHBOARD),
  general: {
    app: path(ROOTS_DASHBOARD,'/overview'),
    profile: path(ROOTS_DASHBOARD, '/profile'),
  },
  success:path(ROOTS_DASHBOARD,'/success'),

  subscription:path(ROOTS_DASHBOARD,'/subscription'),
  // ROOT MENU
  staff: {
    root: path(ROOTS_DASHBOARD, '/staff'),
    list: path(ROOTS_DASHBOARD, '/staff/list'),
    show: path(ROOTS_DASHBOARD, '/staff/show/:id'),
    add: path(ROOTS_DASHBOARD, '/staff/add'),
    edit: path(ROOTS_DASHBOARD, '/staff/edit/:id'),

  },
  service: {
    root: path(ROOTS_DASHBOARD, '/service'),
    lab:path(ROOTS_DASHBOARD, '/service/lab'),
    list: path(ROOTS_DASHBOARD, '/service/list'),
    add: path(ROOTS_DASHBOARD, '/service/add'),
    edit: path(ROOTS_DASHBOARD, '/service/edit'),
  },
  skill: {
    root: path(ROOTS_DASHBOARD, '/skill'),
    list: path(ROOTS_DASHBOARD, '/skill/list'),
    add: path(ROOTS_DASHBOARD, '/skill/add'),
    edit: path(ROOTS_DASHBOARD, '/skill/edit'),
  },
  schedule:{
    root:path(ROOTS_DASHBOARD, '/schedule'),
    doctor:path(ROOTS_DASHBOARD, '/schedule/doctor')
  },
  subscribe:{
    root:path(ROOTS_DASHBOARD, '/subscribe')
  },
  otherExpenses:{
    root:path(ROOTS_DASHBOARD, '/other-expenses'),
    list:path(ROOTS_DASHBOARD, '/other-expenses/list'),
    add:path(ROOTS_DASHBOARD, '/other-expenses/add')
  },
  group: {
    root: path(ROOTS_DASHBOARD, '/group'),
    list: path(ROOTS_DASHBOARD, '/group/list'),
    show: path(ROOTS_DASHBOARD, '/group/show/:id'),
    add: path(ROOTS_DASHBOARD, '/group/add'),
    edit: path(ROOTS_DASHBOARD, '/group/edit/:id')
  },
  doctors: {
    root: path(ROOTS_DASHBOARD, '/doctors'),
    list: path(ROOTS_DASHBOARD, '/doctors/list'),
    show: path(ROOTS_DASHBOARD, '/doctors/show/:id'),
    add: path(ROOTS_DASHBOARD, '/doctors/add'),
    edit: path(ROOTS_DASHBOARD, '/doctors/edit/:id')
  },
  finance: {
    root: path(ROOTS_DASHBOARD, '/finance'),
    overview: path(ROOTS_DASHBOARD, '/finance/overview'),
    staff: path(ROOTS_DASHBOARD, '/finance/staff'),
    staffOverview: path(ROOTS_DASHBOARD, '/finance/staff/:id'),
    doctors:path(ROOTS_DASHBOARD, '/finance/doctors'),
    doctorOverview:path(ROOTS_DASHBOARD, '/finance/doctors/:id'),
    otherExpenses:path(ROOTS_DASHBOARD, '/finance/other-expenses'),
    invoice:path(ROOTS_DASHBOARD, '/finance/invoice'),
    invoiceMore:path(ROOTS_DASHBOARD, '/finance/invoice/:id'),
    invoiceAdd:path(ROOTS_DASHBOARD, '/finance/invoice/add'),
    doctorWork:path(ROOTS_DASHBOARD, '/finance/doctor/work/:id'),
    staffWork:path(ROOTS_DASHBOARD, '/finance/staff/work/:id'),
    labs:path(ROOTS_DASHBOARD, '/finance/labs'),
    labsOverview:path(ROOTS_DASHBOARD, '/finance/labs/:id'),
    labsWork:path(ROOTS_DASHBOARD, '/finance/labs/:id')
  },
  exercises: {
    root: path(ROOTS_DASHBOARD, '/exercises'),
    list: path(ROOTS_DASHBOARD, '/exercises/list'),
    show: path(ROOTS_DASHBOARD, '/exercises/show/:id'),
    add: path(ROOTS_DASHBOARD, '/exercises/add'),
    edit: path(ROOTS_DASHBOARD, '/exercises/edit/:id')
  },
  labs: {
    root: path(ROOTS_DASHBOARD, '/labs'),
  },
  workouts: {
    root: path(ROOTS_DASHBOARD, '/workouts'),
    list: path(ROOTS_DASHBOARD, '/workouts/list'),
    show: path(ROOTS_DASHBOARD, '/workouts/show/:id'),
    add: path(ROOTS_DASHBOARD, '/workouts/add'),
    edit: path(ROOTS_DASHBOARD, '/workouts/edit/:id')
  },
  meals: {
    root: path(ROOTS_DASHBOARD, '/meals'),
    list: path(ROOTS_DASHBOARD, '/meals/list'),
    show: path(ROOTS_DASHBOARD, '/meals/show/:id'),
    add: path(ROOTS_DASHBOARD, '/meals/add'),
    edit: path(ROOTS_DASHBOARD, '/meals/edit/:id')
  },
  programs: {
    root: path(ROOTS_DASHBOARD, '/programs'),
    list: path(ROOTS_DASHBOARD, '/programs/list'),
    show: path(ROOTS_DASHBOARD, '/programs/show/:id'),
    add: path(ROOTS_DASHBOARD, '/programs/add'),
    edit: path(ROOTS_DASHBOARD, '/programs/edit/:id')
  },

  patient:{
    root: path(ROOTS_DASHBOARD, '/patient'),
    list: path(ROOTS_DASHBOARD, '/patient/list'),
    add: path(ROOTS_DASHBOARD, '/patient/add'),
    edit: path(ROOTS_DASHBOARD, '/patient/edit/:id'),
  }
};

export const PATH_DOCS = '';
