import {styled} from '@material-ui/core/styles';
import {Box, Grid, Button, Container, Typography, Tab, Tabs} from '@material-ui/core';
import { varFadeIn, varFadeInUp, MotionInView, varFadeInDown } from '../../animate';
import {PricingPlanCard} from "../pricing";
import useLocales from "../../../hooks/useLocales";
import {PlanFreeIcon, PlanPremiumIcon, PlanStarterIcon} from "../../../assets";
import {useState} from "react";


// const RootStyle = styled('div')(({ theme }) => ({
//   paddingTop: theme.spacing(15),
//   // [theme.breakpoints.up('md')]: {
//   //   paddingBottom: theme.spacing(15)
//   // }
// }));






export default function LandingPricingPlans() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const {translate} = useLocales();
  const PLANS_LAB = [
    {
      subscription: 'starter',
      icon: <PlanFreeIcon />,
      price: 39.99,
      caption: `${translate('app.saveAnnually')} $47.99`,
      lists: [
        { text: `1-5 ${translate('app.staff')}`, isAvailable: true },
        { text: `50 ${translate('app.doctors')}`, isAvailable: true },
        { text: translate('app.financialReport'), isAvailable: true },
        { text: translate('app.unlimitedWorks'), isAvailable: true },
        { text: translate('app.unlimitedServices'), isAvailable: true },
        { text: translate('app.unlimitedSkill'), isAvailable: true }
      ],
      labelAction: `${translate('app.subscribe')} STARTER`
    },
    {
      subscription: 'premium',
      icon: <PlanStarterIcon />,
      price: 159.99,
      caption: `${translate('app.saveAnnually')} $191,99`,
      lists: [
        { text: `1-10 ${translate('app.staff')}`, isAvailable: true },
        { text: `100 ${translate('app.doctors')}`, isAvailable: true },
        { text: translate('app.financialReport'), isAvailable: true },
        { text: translate('app.unlimitedWorks'), isAvailable: true },
        { text: translate('app.unlimitedServices'), isAvailable: true },
        { text: translate('app.unlimitedSkill'), isAvailable: true }
      ],
      labelAction: `${translate('app.subscribe')} PREMIUM`
    },
    {
      subscription: 'PERSONAL',
      icon: <PlanPremiumIcon />,
      price: 0.00,
      caption: `${translate('app.saveAnnually')} $?`,
      lists: [
        { text: `10+ ${translate('app.staff')}`, isAvailable: true },
        { text: `100+ ${translate('app.doctors')}`, isAvailable: true },
        { text: translate('app.financialReport'), isAvailable: true },
        { text: translate('app.unlimitedWorks'), isAvailable: true },
        { text: translate('app.unlimitedServices'), isAvailable: true },
        { text: translate('app.unlimitedSkill'), isAvailable: true }
      ],
      labelAction: translate('app.getAnOffer'),
      monthPriceId:null,
      yearPriceId:null

    }
  ];
  const PLANS_ClINIC = [
    {
      subscription: 'starter',
      icon: <PlanFreeIcon />,
      price: 39.99,
      caption: `${translate('app.saveAnnually')} $47.99`,
      lists: [
        { text: `1-5 ${translate('app.doctors')}`, isAvailable: true },
        { text: `100 ${translate('app.customer')}`, isAvailable: true },
        { text: translate('app.financialReport'), isAvailable: true },
        { text: translate('app.unlimitedWorks'), isAvailable: true },
        { text: translate('app.unlimitedServices'), isAvailable: true },
        { text: translate('app.unlimitedSkill'), isAvailable: true }
      ],
      labelAction: `${translate('app.subscribe')} STARTER`
    },
    {
      subscription: 'premium',
      icon: <PlanStarterIcon />,
      price: 159.99,
      caption: `${translate('app.saveAnnually')} $191,99`,
      lists: [
        { text: `1-10 ${translate('app.doctors')}`, isAvailable: true },
        { text: `200 ${translate('app.customer')}`, isAvailable: true },
        { text: translate('app.financialReport'), isAvailable: true },
        { text: translate('app.unlimitedWorks'), isAvailable: true },
        { text: translate('app.unlimitedServices'), isAvailable: true },
        { text: translate('app.unlimitedSkill'), isAvailable: true }
      ],
      labelAction: `${translate('app.subscribe')} PREMIUM`
    },
    {
      subscription: 'PERSONAL',
      icon: <PlanPremiumIcon />,
      price: 0.00,
      caption: `${translate('app.saveAnnually')} $?`,
      lists: [
        { text: `10+ ${translate('app.doctors')}`, isAvailable: true },
        { text: `200+ ${translate('app.customer')}`, isAvailable: true },
        { text: translate('app.financialReport'), isAvailable: true },
        { text: translate('app.unlimitedWorks'), isAvailable: true },
        { text: translate('app.unlimitedServices'), isAvailable: true },
        { text: translate('app.unlimitedSkill'), isAvailable: true }
      ],
      labelAction: translate('app.getAnOffer'),
      monthPriceId:null,
      yearPriceId:null

    }
  ];
  const PLANS_DOCTOR = [
    {
      subscription: 'starter',
      icon: <PlanFreeIcon />,
      price: 25.99,
      caption: `${translate('app.saveAnnually')} $31.99`,
      lists: [
        { text: `1-5 ${translate('app.lab')}`, isAvailable: true },
        { text: `50 ${translate('app.customer')}`, isAvailable: true },
        { text: translate('app.financialReport'), isAvailable: true },
        { text: translate('app.unlimitedWorks'), isAvailable: true },
        { text: translate('app.unlimitedServices'), isAvailable: true },
        { text: translate('app.unlimitedSkill'), isAvailable: true }
      ],
      labelAction: `${translate('app.subscribe')} STARTER`
    },
    {
      subscription: 'premium',
      icon: <PlanStarterIcon />,
      price: 46.99,
      caption: `${translate('app.saveAnnually')} $56,99`,
      lists: [
        { text: `1-10 ${translate('app.lab')}`, isAvailable: true },
        { text: `100 ${translate('app.customer')}`, isAvailable: true },
        { text: translate('app.financialReport'), isAvailable: true },
        { text: translate('app.unlimitedWorks'), isAvailable: true },
        { text: translate('app.unlimitedServices'), isAvailable: true },
        { text: translate('app.unlimitedSkill'), isAvailable: true }
      ],
      labelAction: `${translate('app.subscribe')} PREMIUM`
    },
    {
      subscription: 'PERSONAL',
      icon: <PlanPremiumIcon />,
      price: 77.99,
      caption: `${translate('app.saveAnnually')} $93,99`,
      lists: [
        { text: `10+ ${translate('app.lab')}`, isAvailable: true },
        { text: `100+ ${translate('app.customer')}`, isAvailable: true },
        { text: translate('app.financialReport'), isAvailable: true },
        { text: translate('app.unlimitedWorks'), isAvailable: true },
        { text: translate('app.unlimitedServices'), isAvailable: true },
        { text: translate('app.unlimitedSkill'), isAvailable: true }
      ],
      labelAction: translate('app.getAnOffer'),
      monthPriceId:null,
      yearPriceId:null

    }
  ];
  return (
    // <RootStyle>
      <Container maxWidth="lg">
        <Box sx={{ mb: 10, textAlign: 'center' }}>
          <MotionInView variants={varFadeInUp}>
            <Typography component="p" variant="overline" sx={{ mb: 2, color: 'text.secondary' }}>
              {translate('app.pricingPlans')}
            </Typography>
          </MotionInView>
          <MotionInView variants={varFadeInDown}>
            <Typography variant="h4" sx={{ mb: 3 }}>
              {translate('app.pricingPlansText')}
            </Typography>
          </MotionInView>
          <MotionInView variants={varFadeInDown}>
          <Tabs
              value={value}
              centered
              onChange={handleChange}
              aria-label="icon position tabs example"
          >
            <Tab icon={<img src="/static/icons/dental-clinic.png" width={24}/>}  label={translate('app.clinic')}  />
            <Tab icon={<img src="/static/icons/biologist.png" width={24}/>}  label={translate('app.lab')}  />
            <Tab icon={<img src="/static/icons/orthodontist.png" width={24}/>} label={translate('app.doctor')} />
          </Tabs>
          </MotionInView>
        </Box>

        {value === 0 && (
            <Grid container spacing={5}>
              {PLANS_ClINIC.map((card, index) => (
                  <Grid item key={index} xs={12} md={4}>
                    <MotionInView variants={index === 1 ? varFadeInDown : varFadeInUp}>
                      <PricingPlanCard planType={0}  card={card} index={index} />
                    </MotionInView>
                  </Grid>
              ))}
            </Grid>
        )}
        {value === 1 && (
            <Grid container spacing={5}>
              {PLANS_LAB.map((card, index) => (
                  <Grid key={index} item xs={12} md={4}>
                    <MotionInView variants={index === 1 ? varFadeInDown : varFadeInUp}>
                      <PricingPlanCard planType={1} card={card} index={index} />
                    </MotionInView>
                  </Grid>
              ))}
            </Grid>
        )}
        {value === 2 && (
            <Grid container spacing={5}>
              {PLANS_DOCTOR.map((card, index) => (
                  <Grid key={index} item xs={12} md={4}>
                    <MotionInView variants={index === 1 ? varFadeInDown : varFadeInUp}>
                      <PricingPlanCard planType={2} card={card} index={index} />
                    </MotionInView>
                  </Grid>
              ))}
            </Grid>
        )}

        <MotionInView variants={varFadeIn}>
          <Box sx={{ p: 5, mt: 10, textAlign: 'center' }}>
            {/*<MotionInView variants={varFadeInDown}>*/}
            {/*  <Typography variant="h3">{translate('app.subscriptionRequest')}</Typography>*/}
            {/*</MotionInView>*/}

            {/*<MotionInView variants={varFadeInDown}>*/}
            {/*  <Typography sx={{ mt: 3, mb: 5, color: 'text.secondary' }}>*/}
            {/*    Please describe your case to receive the most accurate advice.*/}
            {/*  </Typography>*/}
            {/*</MotionInView>*/}

            {/*<MotionInView variants={varFadeInUp}>*/}
            {/*  <Button*/}
            {/*      // to={PATH_DASHBOARD.root}*/}
            {/*      fullWidth*/}
            {/*      size="large"*/}
            {/*      variant="contained"*/}
            {/*      // onClick={()=>openForm() }*/}
            {/*      // disabled={index === 0}*/}
            {/*      // component={RouterLink}*/}
            {/*  >*/}
            {/*    send*/}
            {/*  </Button>*/}
            {/*</MotionInView>*/}
          </Box>
        </MotionInView>
      </Container>
    // </RootStyle>
  );
}
