// useAudioPlayer.js
import { useState, useRef, useEffect } from 'react';

const useAudio = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioUrl, setAudioUrl] = useState('');
    const audioRef = useRef(new Audio());

    const play = (url) => {
        setAudioUrl(url);
        setIsPlaying(true);
    };

    const stop = () => {
        setIsPlaying(false);
    };

    useEffect(() => {
        if (audioUrl) {
            audioRef.current.src = audioUrl;
            audioRef.current.play().then(() => setIsPlaying(true));
        }
    }, [audioUrl]);

    useEffect(() => {
        if (!isPlaying) {
            audioRef.current.pause();
        }
    }, [isPlaying]);

    return { isPlaying, play, stop };
};

export default useAudio;

