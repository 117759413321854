// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';
import {IMAGE_URL} from "../config";

// ----------------------------------------------------------------------

export default function MyAvatar({...other}) {
  const { user } = useAuth();
  return (
    <MAvatar
      src={`${IMAGE_URL}/staff/${other?.image?other?.image:user?.image}`}
      alt={user?.name}
      color={user.image ? 'default' : createAvatar(user?.name).color}
      {...other}
    >
      {createAvatar(user?.name).name}
    </MAvatar>
  );
}
