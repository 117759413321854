import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
import { styled } from '@material-ui/core/styles';
import {
    Card,
    Button,
    Typography,
    Box,
    Stack,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    TextField, DialogActions, MenuItem
} from '@material-ui/core';
import Label from '../../Label';
import {useState} from "react";
import useLocales from "../../../hooks/useLocales";
import useAuth from "../../../hooks/useAuth";
import {RequestPlan} from "../../../utils/fakeRequest";
import {useSnackbar} from "notistack5";
import {MIconButton} from "../../@material-extend";
import closeFill from "@iconify/icons-eva/close-fill";



const RootStyle = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '15px',
}));

// ----------------------------------------------------------------------

PricingPlanCard.propTypes = {
  index: PropTypes.number,
  card: PropTypes.object
};

export default function PricingPlanCard({ card, index,planType}) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const {translate} = useLocales();
    const [personalModal,setPersonalModal] = useState(false)
    const [requestSuccess,setRequestSeccess] = useState(false)
    const [team,setTeam]= useState(null)
    const [name,setName]= useState(null)
    const [lastName,setLastName]= useState(null)
    const [phone,setPhone]= useState(null)
    const [email,setEmail]= useState(null)
    const [type,setType]= useState(planType)
    const { subscription, icon, price, caption, lists, labelAction ,monthPriceId,yearPriceId} = card;

  const openForm = () =>{
      if(!monthPriceId || !yearPriceId){
          setPersonalModal(true)
      }

  }

  const SendRequest = async () =>{
      let params = {
          name:name,
          lastname:lastName,
          phone:phone,
          email:email,
          type:type
      }
      if(name && lastName && phone && email && type > -1){
          const res = await RequestPlan(params)
          if(res.data.status === 200){
              setRequestSeccess(true)
          }
      }else{
          enqueueSnackbar(translate('app.requestErrorText'), {
              variant: 'error',
              action: (key) => (
                  <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                      <Icon icon={closeFill} />
                  </MIconButton>
              )
          });
      }


  }

  return (
    <RootStyle>
      {/*{index === 1 && (*/}
      {/*  <Label*/}
      {/*    color="info"*/}
      {/*    sx={{*/}
      {/*      top: 16,*/}
      {/*      right: 16,*/}
      {/*      position: 'absolute'*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    POPULAR*/}
      {/*  </Label>*/}
      {/*)}*/}

      <Typography variant="overline" sx={{ color: 'text.secondary' }}>
        {subscription}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            $
          </Typography>
        <Typography variant="h2" sx={{ mx: 1 }}>
          {price === 0 ? '?' : price}
        </Typography>
          <Typography
            gutterBottom
            component="span"
            variant="subtitle2"
            sx={{
              alignSelf: 'flex-end',
              color: 'text.secondary'
            }}
          >
            /mo
          </Typography>
      </Box>

      <Typography
        variant="caption"
        sx={{
          color: 'primary.main',
          textTransform: 'capitalize'
        }}
      >
        {caption}
      </Typography>

      <Box sx={{ width: 80, height: 80, mt: 3 }}>{icon}</Box>

      <Stack component="ul" spacing={2} sx={{ my: 5, width: 1 }}>
        {lists.map((item) => (
          <Stack
            key={item.text}
            component="li"
            direction="row"
            alignItems="center"
            spacing={1.5}
            sx={{ typography: 'body2', color: item.isAvailable ? 'text.primary' : 'text.disabled' }}
          >
            <Box component={Icon} icon={checkmarkFill} sx={{ width: 20, height: 20 }} />
            <Typography variant="body2">{item.text}</Typography>
          </Stack>
        ))}
      </Stack>

      <Button
        // to={PATH_DASHBOARD.root}
        fullWidth
        size="large"
        variant="contained"
        onClick={()=>openForm() }
        // disabled={index === 0}
        // component={RouterLink}
      >
        {labelAction}
      </Button>
        <Dialog  open={personalModal} onClose={()=> setPersonalModal(false)}>
            {!requestSuccess && (<DialogTitle>{translate('app.sendApplication')}</DialogTitle>)}
            <DialogContent>
                <Grid item  xs={12} md={12}>
                    <Stack gap={1} sx={{ pt: 0, pl: 0 ,minWidth:'300px',maxWidth:'500px'}} direction={'column'} flexWrap={'wrap'}>
                        {requestSuccess ?(
                            <Stack
                                alignItems="center"
                                spacing={1.5}
                            >
                            <Typography variant="h3" sx={{ mx: 1 }}>
                                {translate('app.thankYou')}
                            </Typography>
                            <Typography align={'center'} sx={{ mx: 1 }}>
                                {translate('app.requestSuccessText')} "{email}"
                            </Typography>
                            </Stack>
                        ) : (

                            <Stack direction={'column'} gap={1}>
                                <TextField
                                    sx={{mt:2}}
                                    id="outlined-select-currency"
                                    select
                                    value={type}
                                    label={translate('app.type')}
                                    fullWidth
                                    onChange={(e)=>setType(e.target.value)}
                                >
                                    <MenuItem key={0} value={0}>
                                        {translate('app.clinic')}
                                    </MenuItem>
                                    <MenuItem key={1} value={1}>
                                        {translate('app.lab')}
                                    </MenuItem>
                                    <MenuItem key={2} value={2}>
                                        {translate('app.doctor')}
                                    </MenuItem>
                                </TextField>
                            <TextField
                                sx={{mt:2}}
                                id="outlined-disabled"
                                label={translate('app.name')}
                                value={name}
                                onChange={(e)=>setName(e.target.value)}
                                fullWidth
                            />
                            <TextField
                                sx={{mt:2}}
                                id="outlined-disabled"
                                label={translate('app.lastname')}
                                value={lastName}
                                onChange={(e)=>setLastName(e.target.value)}
                                fullWidth
                            />
                            <TextField
                                sx={{mt:2}}
                                id="outlined-disabled"
                                label={translate('app.phone')}
                                value={phone}
                                onChange={(e)=>setPhone(e.target.value)}
                                fullWidth
                            />
                            <TextField
                                sx={{mt:2}}
                                id="outlined-disabled"
                                label={translate('app.email')}
                                value={email}
                                onChange={(e)=>setEmail(e.target.value)}
                                fullWidth
                            />
                            {/*<TextField*/}
                            {/*    sx={{mt:2}}*/}
                            {/*    id="outlined-disabled"*/}
                            {/*    label={translate('app.numberOfStaff')}*/}
                            {/*    value={team}*/}
                            {/*    onChange={(e)=>setTeam(e.target.value)}*/}
                            {/*    fullWidth*/}
                            {/*/>*/}
                            </Stack>
                        )}
                    </Stack>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=> setPersonalModal(false)}>
                    {requestSuccess?translate('app.close'):translate('app.cancel')}
                </Button>
                {!requestSuccess && (
                <Button onClick={()=>SendRequest()} autoFocus>
                    {translate('app.send')}
                </Button>
                )}
            </DialogActions>
        </Dialog>
    </RootStyle>

  );
}
