import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from '../utils/axios';
import { setSession } from '../utils/jwt';
import { API_URL } from '../config';
import SimpleDialog from "../pages/components-overview/material-ui/dialog/SimpleDialogs";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('token');
        if (accessToken) {
          setSession(accessToken);
          const response = await axios.get(`${API_URL}/auth/get-me`);
          const user = response.data.info;
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password,patient) => {
    let url = 'auth/sign-in'
    if(patient){
      url = 'auth/sign-in/patient'
    }
    const response = await axios.post(`${API_URL}/${url}`, {
      email,
      password
    });
    if(!response.data.error){
      let count = Object.keys(response.data.info).length;
      const {token} = response.data.info;
      setSession(token);
      dispatch({
        type: 'LOGIN',
        payload: {
          user:response.data.info
        }
      });
      return true
    }else{
      return false
    }

  };

  const register = async (data) => {
    const response = await axios.post(`${API_URL}/auth/sign-up`, data);
    if(!response.data.error){
      const {token} = response.data.info;
      window.localStorage.setItem('token', token);
      dispatch({
        type: 'REGISTER',
        payload: {
          user:response.data.info
        }
      });
      return true
    }else{
      return false
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const resetPassword = () => {};

  const updateProfile = (user) => {
    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: true,
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile
      }}
    >
      {/*<SimpleDialog />*/}
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
