import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import {PATH_ADMIN_DASHBOARD, PATH_AUTH, PATH_DASHBOARD, PATH_PAGE} from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, user } = useAuth();
  if (isAuthenticated) {
    let path = PATH_DASHBOARD.root;
    if (user.rolle === 'business_lab') {
      path = PATH_DASHBOARD.root;
    }else if(user.rolle === 'Doctor'){
      path = PATH_DASHBOARD.root;
    }else if(user.rolle === 'patient'){
      path = PATH_AUTH.home;
    }
    return <Navigate to={`${path}`} />;
  }

  return <>{children}</>;
}
