import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import flashFill from '@iconify/icons-eva/flash-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {alpha, styled, useTheme} from '@material-ui/core/styles';
import {Button, Box, Link, Container, Typography, Stack, Grid, useMediaQuery} from '@material-ui/core';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../routes/paths';
//
import { varFadeIn, varFadeInUp, varWrapEnter, varFadeInRight } from '../../animate';
import Logo from "../../Logo";
import LanguagePopover from "../../../layouts/dashboard/LanguagePopover";
import BigLogo from "../../BigLogo";
import useLocales from "../../../hooks/useLocales";
import MainNavbar from "../../../layouts/main/MainNavbar";

// ----------------------------------------------------------------------

const RootStyle = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  // backgroundColor: theme.palette.grey[400],
  [theme.breakpoints.up('md')]: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    position: 'fixed',
    alignItems:'center'
  }
}));

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(({ theme }) => ({

  zIndex: 10,
  maxWidth: '100%',
  margin: 'auto',
  textAlign: 'center',
  position: 'relative',
  // paddingTop:props.isMobile ? theme.spacing(2):theme.spacing(15),
  paddingBottom: theme.spacing(15),
  [theme.breakpoints.up('md')]: {
    margin: 'unset',
    textAlign: 'left',
  }
}));

const HeaderStyle = styled((props) => <Stack  {...props} />)(({ theme }) => ({
  zIndex: 20,
  position:'relative',
  height:100,
  justifyContent:'space-between',
  alignItems:'center',
  flexDirection:'row',
  [theme.breakpoints.up('xs')]: {
    height:60,
  }
}));

const HeroOverlayStyle = styled(motion.img)({
  zIndex: 9,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

const HeroImgStyle = styled(motion.img)(({ theme }) => ({
  // top: 0,
  // right: 0,
  // bottom: 0,
  // zIndex: 8,
  width: '100%',
  height:'100%',
  // margin: 'auto',
  objectFit:'cover',
  [theme.breakpoints.up('lg')]: {
    // right: '8%',
    // width: 'auto',
    // height: '48vh'
  }
}));
const shadowIcon = (color) => `drop-shadow(2px 2px 2px ${alpha(color, 0.48)})`;
const LogoStyle = styled('img')(({ theme }) => ({
  filter: shadowIcon(theme.palette.primary.main)
}));
// ----------------------------------------------------------------------

export default function LandingHero({setPersonalModal}) {
  const {translate} = useLocales();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
        <Container maxWidth="lg" >
          <ContentStyle  sx={{paddingTop:theme.spacing(isMobile?10:15)}}>
            <Grid container direction={isMobile ? 'column-reverse':'row'} >
              <Grid item sx={{marginTop:theme.spacing(isMobile?2:0)}} xs={12} sm={6} md={6}>
                <motion.div variants={varFadeInRight}>
                  <Typography variant="h3" sx={{ color: 'common.black' }}>
                    {translate('app.slogn')}
                  </Typography>
                </motion.div>
                <motion.div variants={varFadeInRight}>
                  <Typography sx={{ color: 'common.black',mt:1 }}>
                    {translate('app.slognTwo')}
                  </Typography>
                </motion.div>
                <motion.div variants={varFadeInRight}>
                  <Button
                      sx={{marginTop:theme.spacing(isMobile?3:8)}}
                      size="large"
                      variant="contained"
                      component={RouterLink}
                      to={PATH_AUTH.signup}
                      onClick={()=>setPersonalModal(true)}
                      startIcon={<Icon icon={flashFill} width={20} height={20} />}
                  >
                    {translate('app.forPartner')}
                  </Button>
                </motion.div>

                {/*<Stack sx={{marginTop:theme.spacing(isMobile?3:5)}}  direction="row" spacing={1.5} justifyContent={{ xs: 'center', md: 'flex-start' }}>*/}
                {/*  <motion.img width={150} height={45} variants={varFadeInRight} src="/static/home/app-store.png" />*/}
                {/*  <motion.img width={150} height={45} variants={varFadeInRight} src="/static/home/google-play.png" />*/}
                {/*</Stack>*/}
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <HeroImgStyle  alt="hero" src="/static/home/banner-mobile.webp" variants={varFadeInRight} />
              </Grid>
            </Grid>
          </ContentStyle>
        </Container>
      </RootStyle>
      <Box sx={{ height: { md: '100vh' } }} />
    </>
  );
}
