// material
import { alpha, useTheme, styled } from '@material-ui/core/styles';
import { Box, Grid, Card, Container, Typography, useMediaQuery } from '@material-ui/core';
//
import { varFadeInUp, MotionInView, varFadeInDown } from '../../animate';
import useLocales from "../../../hooks/useLocales";

// ----------------------------------------------------------------------

const CARDS = [
  {
    icon: '/static/icons/ic_calendar.png',
    title: 'Scheduling',
    description: 'Effortlessly Manage Appointments for Smooth Patient Flow and Clinic Efficiency'
  },
  {
    icon: '/static/icons/ic_notification.png',
    title: 'Notifications',
    description: 'Get Instant Notifications for Appointments and Updates'
  },
  {
    icon: '/static/icons/ic_data_secure.png',
    title: 'Data Security',
    description: 'Your Data Handled with Utmost Confidentiality'
  },
  {
    icon: '/static/icons/ic_report.png',
    title: 'Analytics and Reporting',
    description:
        'Uncover Insights for Enhanced Decision-Making'
  },
  {
    icon: '/static/icons/ic_budget.png',
    title: 'Financial Management',
    description: 'A Unified Approach to Financial Management for Sustainable Growth'
  },
  {
    icon: '/static/icons/ic_staff_control.png',
    title: 'Staff control',
    description: 'Efficient Staff Control for a Productive and Harmonious Environment'
  },
];

const shadowIcon = (color) => `drop-shadow(2px 2px 2px ${alpha(color, 0.48)})`;

const RootStyle = styled('div')(({ theme }) => ({
  // paddingTop: theme.spacing(15),
  [theme.breakpoints.up('md')]: {
    // paddingBottom: theme.spacing(15)
  }
}));

const CardStyle = styled(Card)(({ theme }) => {
  const shadowCard = (opacity) =>
    theme.palette.mode === 'light'
      ? alpha(theme.palette.grey[500], opacity)
      : alpha(theme.palette.common.black, opacity);

  return {
    maxWidth: 380,
    minHeight: 300,
    margin: 'auto',
    textAlign: 'center',
    padding: theme.spacing(5, 1, 0),
    boxShadow: `-40px 40px 80px 0 ${shadowCard(0.48)}`,
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
    },
    '&.cardLeft': {
      [theme.breakpoints.up('md')]: { marginTop: -40 }
    },
    '&.cardCenter': {
      [theme.breakpoints.up('md')]: {
        marginTop: -80,
        backgroundColor: theme.palette.background.paper,
        boxShadow: `-40px 40px 80px 0 ${shadowCard(0.4)}`,
        '&:before': {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          content: "''",
          margin: 'auto',
          position: 'absolute',
          width: 'calc(100% - 40px)',
          height: 'calc(100% - 40px)',
          borderRadius: theme.shape.borderRadiusMd,
          backgroundColor: theme.palette.background.paper,
          boxShadow: `-20px 20px 40px 0 ${shadowCard(0.12)}`
        }
      }
    }
  };
});

const CardIconStyle = styled('img')(({ theme }) => ({
  width: 60,
  height: 60,
  margin: 'auto',
  objectFit:'cover',
  marginBottom: theme.spacing(4),
  filter: shadowIcon(theme.palette.primary.main)
}));

// ----------------------------------------------------------------------

export default function LandingMinimalHelps() {
  const {translate} = useLocales();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLight = theme.palette.mode === 'light';
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const CARDS = [
    {
      icon: '/static/icons/ic_calendar.png',
      title: translate('app.scheduling'),
      description: translate('app.schedulingText')
    },
    {
      icon: '/static/icons/ic_notification.png',
      title: translate('app.notifications'),
      description: translate('app.notificationsText')
    },
    {
      icon: '/static/icons/ic_data_secure.png',
      title: translate('app.dataSecurity'),
      description: translate('app.dataSecurityText')
    },
    {
      icon: '/static/icons/ic_report.png',
      title: translate('app.analyticsAndReporting'),
      description:translate('app.analyticsAndReportingText')
    },
    {
      icon: '/static/icons/ic_budget.png',
      title: translate('app.financialManagement'),
      description: translate('app.financialManagementText')
    },
    {
      icon: '/static/icons/ic_staff_control.png',
      title: translate('app.staffControl'),
      description: translate('app.staffControlText')
    },
  ];

  return (
    <RootStyle sx={{paddingTop: theme.spacing(isMobile ? 2 : 15)}}>
      <Container maxWidth="lg">
        <Box sx={{ mb: { xs: 5, md: 12 } }}>
          <MotionInView variants={varFadeInUp}>
            <Typography component="p" variant="overline" sx={{ mb: 2, color: 'text.secondary', textAlign: 'center' }}>
              {translate('app.awesomeFeatures')}
            </Typography>
          </MotionInView>
          <MotionInView variants={varFadeInDown}>
            <Typography variant="h3" sx={{ textAlign: 'center' }}>
              {translate('app.serviceTitle')}
            </Typography>
          </MotionInView>
        </Box>

        <Grid container spacing={isDesktop ? 5 : 5}>
          {CARDS.map((card, index) => (
            <Grid key={card.title} item xs={12} md={4}>
              <MotionInView variants={varFadeInUp}>
                <CardStyle>
                  <CardIconStyle
                    src={card.icon}
                    alt={card.title}

                  />
                  <Typography variant="h6" paragraph>
                    {card.title}
                  </Typography>
                  <Typography sx={{ color: isLight ? 'text.secondary' : 'common.white' }}>
                    {card.description}
                  </Typography>
                </CardStyle>
              </MotionInView>
            </Grid>
          ))}
        </Grid>
      </Container>
    </RootStyle>
  );
}
