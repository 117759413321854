import { useState, useEffect } from 'react';
import io from 'socket.io-client';
import {GetConsulting} from "../utils/fakeRequest";
import useAudio from "./useAudio";
import NotificationSound from "../assets/sound/sound.mp3";
import useSound from 'use-sound';
import {API_WS} from "../config";

const useNotification = (user) => {
    const [sound,setSound] =  useState(false)
    const [play] = useSound(NotificationSound);
    const [socket, setSocket] = useState(null);
    const [status, setStatus] = useState('Disconnected');
    const [notifications, setNotifications] = useState([]);
    const [unreadMessages, setUnreadMessages] = useState([]);
    useEffect(() => {
        const newSocket = io.connect(API_WS);
        setSocket(newSocket);

        return () => {
            newSocket.disconnect();
        };
    }, []);


    useEffect(() => {
        if (socket && user) {
            const userId = `${user.id}_${user.rolle}`;
            socket.emit("set_user_id", userId);

            socket.on("connect", () => {
                setStatus('Connected');
            });

            socket.on("disconnect", () => {
                setStatus('Disconnected');
            });

            socket.on("receive_message", (data) => {
                setNotifications((prevNotifications) => [...prevNotifications, data?.message]);
                play()
                setStatus('Message Received');
            });

            socket.on("receive_message_update", (data) => {
                setNotifications((prevNotifications) => {
                    const notificationId = data?.message.id;

                    // Check if the notification with the same id already exists
                    const existingIndex = prevNotifications.findIndex(
                        (notification) => notification.id === notificationId
                    );

                    // If it doesn't exist, add the new notification
                    if (existingIndex === -1) {
                        return [...prevNotifications, data?.message];
                    }

                    // If the notification already exists, update the item
                    const updatedNotifications = [...prevNotifications];
                    updatedNotifications[existingIndex] = data?.message;

                    return updatedNotifications;
                });
                setStatus('Message Received');
            });


            // Fetch notifications on socket connection
            GetConsulting(user?.id,user.rolle).then((res) => {
                setNotifications(res.data.info);
            });
        }
    }, [socket, user]);

    const sendConsalting = (targetUserId, message) => {
        const userId = `${user.id}_${user.rolle}`;
        socket.emit("send_message", { userId, targetUserId, message });
        setStatus('Message Sent');
    };

    const updateConsalting = (targetUserId, message) => {
        const userId = `${user.id}_${user.rolle}`;
        socket.emit("update_message", { userId, targetUserId, message });
        setNotifications((prevNotifications) => {
            const notificationId = message.id;

            // Check if the notification with the same id already exists
            const existingIndex = prevNotifications.findIndex(
                (notification) => notification.id === notificationId
            );



            const updatedNotifications = [...prevNotifications];
            updatedNotifications[existingIndex] = message;

            return updatedNotifications;
        });
        setStatus('Message Sent');
    };
    const markMessagesAsRead = () => {
        if (socket) {
            socket.emit('read_message');
        }
    };

    return {
        socket,
        sendConsalting,
        status,
        notifications,
        unreadMessages,
        markMessagesAsRead ,
        updateConsalting,
        sound,
        setSound
    };
};

export default useNotification;
