import { motion } from 'framer-motion';
// material
import { styled } from '@material-ui/core/styles';
import { Button, Box, Container, Typography } from '@material-ui/core';
//
import { varFadeInDown, varFadeInUp, MotionInView } from '../../animate';
import useLocales from "../../../hooks/useLocales";
import StripeButtonPay from "../../StripeButtonPay";

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 456,
  margin: 'auto',
  overflow: 'hidden',
  paddingBottom: theme.spacing(10),
  borderRadius: theme.shape.borderRadiusMd,
  backgroundImage: `linear-gradient(135deg,
    ${theme.palette.primary.main} 50%,
    ${theme.palette.primary.dark} 80%)`,
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    maxWidth: '100%',
    paddingBottom: 0,
    alignItems: 'center'
  }
}));

// ----------------------------------------------------------------------

export default function LandingAdvertisement() {
    const {translate} = useLocales();
  return (
    // <Container maxWidth="lg">
      <ContentStyle>
        <MotionInView
          variants={varFadeInUp}
          // sx={{
          //   mb: { xs: 3, md: 0 }
          // }}
        >
          <motion.div animate={{ y: [-20, 0, -20] }} transition={{ duration: 4, repeat: Infinity }}>
            <Box component="img" alt="rocket" src="/static/illustrations/subscription.webp" sx={{ maxWidth: 380, width: 1 }} />
          </motion.div>
        </MotionInView>

        <Box
          sx={{
            pl: { md: 10 },
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          <MotionInView variants={varFadeInDown} sx={{ color: 'common.white', mb: 5 }}>
            <Typography variant="h2">
              Get started PRO
              <br />{translate('app.monthly')} 43.30$
            </Typography>
          </MotionInView>
          <MotionInView variants={varFadeInDown}>
              <StripeButtonPay title={translate('app.subscribe')} productId={'price_1ONOI4A2EpIxWhOLU3bK2qPH'}/>
          </MotionInView>
        </Box>
      </ContentStyle>
    // </Container>
  );
}
