import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, styled } from '@material-ui/core/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListSubheader, ListItemButton } from '@material-ui/core';
import useAuth from '../hooks/useAuth';
import useLocales from "../hooks/useLocales";

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    color: theme.palette.text.primary
  })
);

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main
  }
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 38,
  height: 38,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor:'rgba(121,143,176,0.24)',
  padding:3,
  borderRadius:3
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  active: PropTypes.func,
  isShow: PropTypes.bool,
  item: PropTypes.object
};

function NavItem({ item, active, isShow,show }) {
  const {translate} = useLocales();
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };
  const disableRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: '#ff000010',
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };
  if(show) {
    return (
        <ListItemStyle
            component={RouterLink}
            to={path}
            sx={{
              ...(isActiveRoot && activeRootStyle)
            }}
        >
          <ListItemIconStyle>
            {icon && icon}
          </ListItemIconStyle>
          {isShow && (
              <>
                <ListItemText disableTypography={true} primary={translate(title)}/>
                {info && info}
              </>
          )}
        </ListItemStyle>
    );
  }else{
    return (
        <ListItemStyle
            sx={{
              ...disableRootStyle
            }}
        >
          <ListItemIconStyle>
            {icon && icon}
          </ListItemIconStyle>
          {isShow && (
              <>
                <ListItemText disableTypography={true} primary={translate(title)}/>
                {info && info}
              </>
          )}
        </ListItemStyle>
    );
  }
}

NavSection.propTypes = {
  isShow: PropTypes.bool,
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, isShow = true, ...other }) {
  const { user } = useAuth();
  const {translate} = useLocales();
  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const { subheader, items } = list;
        return (
          <List key={subheader} disablePadding>
            {isShow && <ListSubheaderStyle>{translate(subheader)}</ListSubheaderStyle>}
            {items.map((item) =>
              // user.role === 'trainer' && item.title !== 'Trainers' ? (
                <NavItem key={item.title} item={item} active={match} isShow={isShow} show={item?.show} />
              // ) : (
              //   user.role !== 'trainer' && <NavItem key={item.title} item={item} active={match} isShow={isShow} />
              // )
            )}
          </List>
        );
      })}
    </Box>
  );
}
