import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack5';
import useLocales from "../../hooks/useLocales";
import useAuth from "../../hooks/useAuth";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import * as Yup from "yup";
import {LoginForm} from "../authentication/login";
import SelectType from "./SelectType";




export default function PatientSignupDialog({open,close}) {
    const handleClose = () =>{
        close(false)
    }
    return (
        <Dialog maxWidth={'sm'}  open={open} onClose={handleClose}>
            <DialogContent sx={{minWidth:320,maxWidth:400}}>

                <LoginForm patient={true}/>
            </DialogContent>
        </Dialog>
    );
}
