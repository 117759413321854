import {useEffect, useRef, useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import { alpha } from '@material-ui/core/styles';
import {Badge} from '@material-ui/core';
import { MIconButton } from '../../components/@material-extend';
import useAuth from "../../hooks/useAuth";
import useNotification from "../../hooks/useNotification";
import useAudio from "../../hooks/useAudio";
import NotificationSound from "../../assets/sound/sound.mp3";
import calendarFill from "@iconify/icons-eva/calendar-fill";
import {PATH_PAGE} from "../../routes/paths";

export default function PatientNotification() {
    const {user} = useAuth()
    const { isPlaying, play } = useAudio();
    const { socket, status, notifications,setSound,  unreadMessages,updateConsalting,sound } = useNotification(user);
    const navigate = useNavigate();
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const totalUnRead = notifications.filter((item) => item.patient_status === 'unread').length;


    useEffect(()=>{

    },[socket])

    const handleOpen = () => {
        setOpen(true);
        navigate(PATH_PAGE.consulting)
    };

    useEffect(() => {
        // console.log('sound', sound);

        if (sound) {
            play(NotificationSound);
            setTimeout(() => {
                setSound(false);
            }, 2000);
        }
    }, [sound, play]);

    // const handleClose = () => {
    //     setOpen(false);
    // };

    // const handleMarkAllAsRead = () => {
    //     // markMessagesAsRead()
    // };

    // const handelStatus = (status,item) =>{
    //     console.log('asfdsf',item)
    //     if(user.rolle === "Doctor"){
    //         let targetID = `${item.patient_id}_patient`
    //         let data = {...item,status:status,doctor_status:'read',patient_status:'unread'}
    //
    //         console.log('targetID',targetID)
    //         console.log('data',data)
    //         updateConsalting(targetID,data)
    //     }
    //
    // }


    return (
        <>
            <MIconButton
                ref={anchorRef}
                size="large"
                color={open ? 'primary' : 'default'}
                onClick={handleOpen}
                sx={{
                    ...(open && {
                        bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
                    })
                }}
            >
                <Badge badgeContent={totalUnRead} color="error">
                    <Icon icon={calendarFill} width={25} height={25} />
                </Badge>
            </MIconButton>
        </>
    );
}
