import { styled } from '@material-ui/core/styles';
import Page from '../components/Page';
import {
    LandingHero,
    LandingMinimal,
    LandingPricingPlans,
    LandingHugePackElements
} from '../components/_external-pages/landing';
import LandingLanguages from "../components/_external-pages/landing/LandingLanguages";
import useLocales from "../hooks/useLocales";
import {useRef, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Stack,
    TextField,
    Typography
} from "@material-ui/core";
import {RequestPlan} from "../utils/fakeRequest";
import {MIconButton} from "../components/@material-extend";
import {Icon} from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import {useSnackbar} from "notistack5";
import {Alert} from "@mui/material";


const RootStyle = styled(Page)({
    height: '100%'
});

const ContentStyle = styled('div')(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: theme.palette.background.default
}));

export default function SubscribeForm({personalModal,setPersonalModal}) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const {translate} = useLocales();
    // const [personalModal,setPersonalModal] = useState(false)
    const [requestSuccess,setRequestSeccess] = useState(false)
    const [team,setTeam]= useState(null)
    const [name,setName]= useState(null)
    const [lastName,setLastName]= useState(null)
    const [phone,setPhone]= useState(null)
    const [email,setEmail]= useState(null)
    const [type,setType]= useState(null)






    const SendRequest = async () =>{
        let params = {
            name:name,
            lastname:lastName,
            phone:phone,
            email:email,
            type:type
        }
        if(name && lastName && phone && email && type > -1){
            const res = await RequestPlan(params)
            if(res.data.status === 200){
                setRequestSeccess(true)
            }
        }else{
            enqueueSnackbar(translate('app.requestErrorText'), {
                variant: 'error',
                action: (key) => (
                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </MIconButton>
                )
            });
        }


    }
    return (
            <Dialog maxWidth={'sm'} open={personalModal} onClose={()=> setPersonalModal(false)}>
                {!requestSuccess && (<DialogTitle>{translate('app.welcomeInfo')}</DialogTitle>)}
                <DialogContent>
                    <Alert sx={{mt:2}} severity="info">
                        {translate('app.infoText')}
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=> setPersonalModal(false)}>
                        {translate('app.close')}
                    </Button>

                    {/*{!requestSuccess && (*/}
                    {/*    <Button onClick={()=>SendRequest()} autoFocus>*/}
                    {/*        {translate('app.send')}*/}
                    {/*    </Button>*/}
                    {/*)}*/}
                </DialogActions>
            </Dialog>
    );
}
